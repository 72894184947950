import React, { useEffect, useState, useCallback } from 'react';
import { client } from '../contentful/client';

const PlacedSlider = () => {
    const [isSliderLoading, setIsSliderLoading] = useState(true);
    const [sliderSlides, setSliderSlides] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);

    // Clean up slide data
    const cleanUpSlides = useCallback((rawData) => {
        const cleanSlides = rawData.map((slide) => {
            const { sys, fields } = slide;
            const { id } = sys;
            const slideImg = fields.img?.fields?.file?.url || '';
            const formattedSlideImg = slideImg.startsWith('http') ? slideImg : `https:${slideImg}`;
            return { id, slideImg: formattedSlideImg };
        });
        setSliderSlides(cleanSlides);
    }, []);

    // Fetch slides from Contentful
    const getSlides = useCallback(async () => {
        setIsSliderLoading(true);
        try {
            const response = await client.getEntries({ content_type: 'slider' });
            cleanUpSlides(response.items);
        } catch (error) {
            console.error('Error fetching slides:', error);
        } finally {
            setIsSliderLoading(false);
        }
    }, [cleanUpSlides]);

    useEffect(() => {
        getSlides();
    }, [getSlides]);

    // Navigate to the next slide
    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % sliderSlides.length);
    };

    // Navigate to the previous slide
    const prevSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + sliderSlides.length) % sliderSlides.length);
    };

    // Auto-slide functionality
    useEffect(() => {
        const interval = setInterval(nextSlide, 3000); // Change slide every 3 seconds
        return () => clearInterval(interval); // Clean up on unmount
    }, [sliderSlides, nextSlide]);

    return (
        <div className="max-w-xl p-6">
            {isSliderLoading ? (
                <p className="text-center text-gray-500">Loading...</p>
            ) : (
                <div className="relative w-full h-full overflow-hidden rounded-lg shadow-lg border border-gray-200">
                    {/* Slider Inner */}
                    <div
                        className="flex transition-transform duration-500 ease-in-out"
                        style={{
                            transform: `translateX(-${currentIndex * 100}%)`,
                        }}
                    >
                        {sliderSlides.map((slide, index) => (
                            <div
                                key={slide.id}
                                className="min-w-full h-full bg-gray-100 flex items-center justify-center"
                            >
                                <img
                                    src={slide.slideImg}
                                    alt={`Slide ${index + 1}`}
                                    className="w-full h-full object-cover"
                                />
                            </div>
                        ))}
                    </div>

                    {/* Previous Button */}
                    <button
                        onClick={prevSlide}
                        className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-blue-500 text-white rounded-full w-10 h-10 flex items-center justify-center shadow-lg hover:bg-blue-600"
                    >
                        &#10094;
                    </button>

                    {/* Next Button */}
                    <button
                        onClick={nextSlide}
                        className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-blue-500 text-white rounded-full w-10 h-10 flex items-center justify-center shadow-lg hover:bg-blue-600"
                    >
                        &#10095;
                    </button>
                </div>
            )}
        </div>
    );
};

export default PlacedSlider;
