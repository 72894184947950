import React, { useState, useEffect } from "react";
import "../css/PopUpForm.css";
import { useForm } from "react-hook-form";
import IMG from '../assets/FormImg.png'

const PopUpForm = () => {
  const [isOpen, setIsOpen] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsOpen(true);
    }, 5000); // 7 seconds delay

    return () => clearTimeout(timer); // Cleanup timeout on unmount
  }, []);

  const closeForm = () => setIsOpen(false);

  const onSubmit = async (data) => {
    try {
      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          access_key: "353dd854-1ca9-4730-8291-1ead344c700d",
          subject: "New Contact Form Submission",
          from_name: "ITAccurate",
          name: data.name,
          phone: data.phone,
          course: data.course,
        }),
      });

      const result = await response.json();

      if (result.success) {
        alert("Form submitted successfully!");
        reset(); // Clear the form fields
        closeForm(); // Close the popup form
      } else {
        alert("Something went wrong. Please try again.");
      }
    } catch (error) {
      alert("An error occurred while submitting the form.");
    }
  };

  return (
    <>
      {isOpen && (
        <div className="popup-form-overlay">
          <div className="popup-form-container shadow-lg">
            <div className="popup-form-left lg:w-2/3 bg-[#02ADEF] text-white p-6 hidden lg:block">
              <h3 className="text-xl font-bold mb-2">
                Up-Skill your Career with the world's
              </h3>
              <p className="font-semibold text-yellow-400">
                Effective Online & Classroom Training
              </p>
              <p className="mt-2">
                Trusted by <span className="font-bold">3,50,850+</span> Satisfied Learners &{" "}
                <span className="font-bold">123+</span> Corporates
              </p>
              <div className="mt-4">
                <img
                  src={IMG}
                  alt="Training Illustration"
                  className="w-[45%] m-auto"
                />
              </div>
            </div>

            <div className="popup-form-right p-6 w-full bg-white">
              <div className="popup-form-header flex justify-between items-center mb-4">
                <h2 className="text-xl font-bold">Contact Us</h2>
                <button
                  onClick={closeForm}
                  className="popup-close-btn text-gray-500 hover:text-black"
                >
                  ✖
                </button>
              </div>

              <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
                {/* Name Input */}
                <div>
                  <input
                    type="text"
                    placeholder="Your Name"
                    {...register("name", {
                      required: "Name is required",
                    })}
                    className="w-full border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
                  />
                  {errors.name && (
                    <p className="text-red-500 text-sm">{errors.name.message}</p>
                  )}
                </div>

                {/* Phone Input */}
                <div>
                  <input
                    type="tel"
                    placeholder="Your Phone Number"
                    {...register("phone", {
                      required: "Phone number is required",
                      pattern: {
                        value: /^[0-9]{10}$/,
                        message: "Enter a valid 10-digit number",
                      },
                    })}
                    className="w-full border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
                  />
                  {errors.phone && (
                    <p className="text-red-500 text-sm">{errors.phone.message}</p>
                  )}
                </div>

                {/* Course Dropdown */}
                <div>
                  <select
                    {...register("course", {
                      required: "Please select a course",
                    })}
                    className="w-full border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
                  >
                    <option value="">-- Select a Course --</option>
                    <option value="Salesforce">Salesforce</option>
                    <option value="AWS">AWS</option>
                    <option value="DevOps">DevOps</option>
                    <option value="Python">Python</option>
                    <option value="AI & ML">AI & ML</option>
                    <option value="Data Analytics">Data Analytics</option>
                    <option value="Business Analytics">Business Analytics</option>
                    <option value="Share Market">Share Market</option>
                  </select>
                  {errors.course && (
                    <p className="text-red-500 text-sm">{errors.course.message}</p>
                  )}
                </div>

                {/* Submit Button */}
                <button
                  type="submit"
                  className="w-full bg-blue-500 text-white py-2 rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400"
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PopUpForm;
