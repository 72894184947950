import React, { useState } from "react";
import { FaProjectDiagram, FaGraduationCap, FaBriefcase, FaHandsHelping, FaChalkboardTeacher, FaNetworkWired } from "react-icons/fa";
import "../css/JobPreparation.css";

const JobPreparation = () => {
  const [activeTab, setActiveTab] = useState("CourseCompletion");

  const renderContent = () => {
    switch (activeTab) {
      case "CourseCompletion":
        return (
          <table className="tab-content-table">
            <thead>
              <tr>
                <th colSpan="2" style={{ fontWeight: "bold" }}>Successfully Completing Your Course</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ fontWeight: "bold" }}><FaGraduationCap className="icon" /> Practical Learning with Internships</td>
                <td>
                Join our internship program to get real-world experience and see how your studies apply in the workplace. It's a great way to boost your career skills and build confidence for your future.
                </td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bold" }}><FaProjectDiagram className="icon" /> Real-World Project Implementation</td>
                <td>
                Work on projects that align with industry needs to strengthen your technical skills. Get practical experience and demonstrate your ability to achieve meaningful results in real-world scenarios.
                </td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bold" }}><FaBriefcase className="icon" /> Professional Resume Crafting</td>
                <td>
                Craft a resume that showcases your skills and achievements in the best light. Stand out to employers with a polished, professional profile that paves the way for exciting career opportunities.
                </td>
              </tr>
            </tbody>
          </table>
        );
      case "InterviewPreparation":
        return (
          <table className="tab-content-table">
            <thead>
              <tr>
                <th colSpan="2" style={{ fontWeight: "bold" }}>Getting Ready for Your Interviews</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ fontWeight: "bold" }}><FaChalkboardTeacher className="icon" /> Building Core Skills</td>
                <td>
                Enhance your problem-solving and technical skills with targeted training. Stay prepared with the knowledge and expertise needed to succeed in competitive job markets.
                </td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bold" }}><FaHandsHelping className="icon" /> Simulated Interview Practice</td>
                <td>
                Take part in mock interviews designed to align with your career goals. Build confidence, perfect your responses, and get ready for real interview situations.
                </td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bold" }}><FaNetworkWired className="icon" /> Collaborative Group Discussions</td>
                <td>
                Improve your communication and critical thinking through interactive group discussions. Strengthen your teamwork abilities and learn to express your ideas clearly and confidently.
                </td>
              </tr>
            </tbody>
          </table>
        );
      case "Placement":
        return (
          <table className="tab-content-table">
            <thead>
              <tr>
                <th colSpan="2" style={{ fontWeight: "bold" }}>Placement Assistance</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ fontWeight: "bold" }}><FaBriefcase className="icon" /> Support Until You Land Your Dream Job</td>
                <td>
                Get continuous guidance and support until you land a position at a leading multinational company. Stay focused and confident as you work toward reaching your career goals.
                </td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bold" }}><FaHandsHelping className="icon" /> Referral Opportunities</td>
                <td>
                Take advantage of our referral programs to access exclusive opportunities with top organizations. Open the door to a rewarding and impactful career through our strong network.
                </td>
              </tr>
            </tbody>
          </table>
        );
      default:
        return null;
    }
  };

  return (
    <div className="job-preparation">
      <h2 className="main-heading">The Path to Career Success at IT Accurate</h2>
      <div className="tabs">
        <button
          className={`tab-btn ${activeTab === "CourseCompletion" ? "active" : ""}`}
          onClick={() => setActiveTab("CourseCompletion")}
        >
          Mastery and Certification

        </button>
        <button
          className={`tab-btn ${activeTab === "InterviewPreparation" ? "active" : ""}`}
          onClick={() => setActiveTab("InterviewPreparation")}
        >
        Cracking the Interview
        </button>
        <button
          className={`tab-btn ${activeTab === "Placement" ? "active" : ""}`}
          onClick={() => setActiveTab("Placement")}
        >
         Job Assistance Program

        </button>
      </div>
      {renderContent()}
    </div>
  );
};

export default JobPreparation;
