import React from 'react'
import Hero from '../components/Hero';
import CarrerDev from "../components/CarrerDev"
import WhyUs from '../components/WhyUs';
import MarqueeLogos from '../components/MarqueeLogos';
import MOU from '../components/MouSlider'
import Certifications from '../components/Certifications';
import Brands from '../components/Brands';
import AwardsSlider from '../components/AwardsSlider';
import Marq from '../components/CustomMarquee';
import JobPreparation from '../components/JobPreparation';
import TrainerDetails from '../components/TrainerDetails';
import FeesDetails from '../components/FeesDetails';
import SalaryHikeSection from '../components/SalaryHike';
const Home = () => {
  return (
    <div className='Home'>
      <Hero />
      <Brands />
      <Marq />
      <CarrerDev />
      <SalaryHikeSection />
      <WhyUs />
      <JobPreparation />
      <MOU />
      <FeesDetails/>
      <TrainerDetails/>
      <MarqueeLogos />
      <Certifications />
      <AwardsSlider />
    </div>
  )
}

export default Home
