import React, { useEffect, useRef } from "react";
import "../../css/DevOps.css";
import certificateImg from '../../assets/Certificates/IT accurate certificate.png';
// import IMG from '../../assets/logo.svg'
import Roadmap from "../../components/Roadmap";
import WhatsDevOps from "../../components/WhatsDevOps";
// import PageForm from '../../components/PageForm';
import Syllabus from "../../components/Syllabus";
import KeyFeatures from "../../components/KeyFeatures";
import Faq from "../../components/Faq";
import "slick-carousel/slick/slick.css"; // Importing slick carousel styles
import "slick-carousel/slick/slick-theme.css"; // Importing slick theme styles
import StickyNavbar from "../../components/StickyNavbar";
// import dev1 from '../../assets/Projects/Devops/DEV1.png';
// import dev2 from '../../assets/Projects/Devops/DEV2.png';
// import dev3 from '../../assets/Projects/Devops/DEV3.png';
// import dev4 from '../../assets/Projects/Devops/DEV4.png';
// import dev5 from '../../assets/Projects/Devops/DEV5.png';
// import dev6 from '../../assets/Projects/Devops/DEV6.png';
// import dev7 from '../../assets/Projects/Devops/DEV7.png';
// import dev8 from '../../assets/Projects/Devops/DEV8.png';
// import rev1 from '../../assets/Reviews/DEVOPS/devrev1.png';
// import rev2 from '../../assets/Reviews/DEVOPS/devrev2.png';
// import rev3 from '../../assets/Reviews/DEVOPS/devrev3.png';
// import rev4 from '../../assets/Reviews/DEVOPS/devrev4.png';
// import rev5 from '../../assets/Reviews/DEVOPS/devrev5.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileAlt,
  faChalkboardTeacher,
  faBriefcase,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
// import SideDropdown from '../../components/SideDropdown';
import MarqueeLogos from "../../components/MarqueeLogos";
import ContactForm from "../../components/ContactForm";
import CourseOpportunities from '../../components/CourseOpportunities'

const SAPLESL = () => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);

  //   useEffect(() => {
  //     //Function to create the YouTube player
  //    const createPlayer = () => {
  //      if (window.YT && window.YT.Player) {
  //        playerRef.current = new window.YT.Player(videoRef.current, {
  //          videoId: 'VhmCwdaf63k', // Updated YouTube video ID
  //          events: {
  //            onReady: (event) => {
  //              // event.target.mute(); // Mute the video to allow autoplay
  //              event.target.playVideo();
  //            },
  //          },
  //          playerVars: {
  //            loop: 1,
  //            controls: 0, // Hide video controls
  //            modestbranding: 1, // Minimal YouTube branding
  //            playlist: 'VhmCwdaf63k', // Required for looping
  //          },
  //        });
  //      }
  //    };

  //    // Load YouTube IFrame API if it's not already loaded
  //    if (!window.YT) {
  //      const tag = document.createElement('script');
  //      tag.src = 'https://www.youtube.com/iframe_api';
  //      const firstScriptTag = document.getElementsByTagName('script')[0];
  //      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

  //      // Set up callback for when the API is ready
  //      window.onYouTubeIframeAPIReady = () => {
  //        createPlayer();
  //      };
  //    } else {
  //      createPlayer();
  //    }
  //  }, []);

  const roadmapData = [
    {
      title: "Introduction",
      points: [
        "What is SAP?",
        "What is SAP LE & SL?",
        "What is SAP HANA?",
        "SAP course",
        "SAP certification",
        "SAP jobs in india",
        "SAP jobs in mumbai",
      ],
    },
    {
      title: "Stakeholder Alignment",
      points: [
        "Engage with key stakeholders, including logistics, warehouse, and service teams.",
        "Understand their requirements and expectations for LE and SL processes.",
      ],
    },
    {
      title: "Integration Planning",
      points: [
        "Identify integration points between SAP LE and SL modules.",
        "Ensure seamless data flow and communication between logistics execution and service parts planning.",
      ],
    },
    {
      title: "Technology Infrastructure Assessment",
      points: [
        "Assess the existing technology infrastructure to ensure it can support the upgraded or new SAP modules.",
        "Consider cloud-based solutions for scalability and flexibility.",
      ],
    },
    {
      title: "Customization and Configuration",
      points: [
        "Customize and configure SAP LE and SL modules to align with specific business processes and requirements.",
        "Optimize settings for warehouse management, transportation, and service parts planning.",
      ],
    },
    {
      title: "Testing and Quality Assurance",
      points: [
        "Conduct rigorous testing of SAP LE and SL functionalities to identify and rectify any issues.",
        "Ensure that integration points are thoroughly tested for data accuracy and consistency.",
      ],
    },
    {
      title: "Compliance and Reporting",
      points: [
        "Ensure that the SAP LE and SL setup complies with industry regulations and standards.",
        "Implement robust reporting mechanisms for real-time visibility into logistics and service parts performance.",
      ],
    },
    {
      title: "Resume preparation",
      points: [
        "Include keywords.",
        "How to prepare resume.",
        "How to prepare resume for freshers.",
        "Show impact.",
        "Include soft skills.",
        "Be unique.",
      ],
    },
  ];

  const modulesData = [
    {
      title: "SAP",
      points: [
        "IT Accurate Training in Mumbai offers expert SAP training, empowering you to efficiently manage SAP systems and ensure seamless operations.",
      ],
      link: "/best-sap-training",
    },
    {
      title: "SAP BASIS",
      points: [
        "IT Accurate Training in Mumbai offers expert SAP BASIS training, empowering you to efficiently manage SAP systems and ensure seamless operations.",
      ],
      link: "/sap-basis-training-in-mumbai",
    },
    {
      title: "SAP MM",
      points: [
        "SAP MM (Materials Management) is a module in SAP ERP that manages procurement processes and inventory management. IT Accurate Institute in Mumbai offers comprehensive training in SAP MM for effective utilization.",
      ],
      link: "/sap-mm",
    },
    {
      title: "SAP FICO",
      points: [
        "SAP FICO, offered by IT Accurate Institute Mumbai, encompasses financial accounting and controlling modules within the SAP ERP system, facilitating comprehensive financial management and reporting for organizations.",
      ],
      link: "/sap-fico",
    },
    {
      title: "SAP ABAP",
      points: [
        "SAP ABAP is a programming language used for developing applications within the SAP ecosystem. IT Accurate Institute in Mumbai is a training center specializing in SAP courses, including ABAP programming to equip professionals with the skills.",
      ],
      link: "/sap-abap",
    },
    {
      title: "SAP HCM",
      points: [
        "SAP HCM (Human Capital Management) is an integrated software suite by SAP that manages HR processes, from recruitment to payroll. IT Accurate Institute in Mumbai likely provides training in SAP HCM and related modules.",
      ],
      link: "/sap-hcm",
    },
    {
      title: "SAP SCM",
      points: [
        "SAP SCM (Supply Chain Management) is a software suite by SAP that integrates and optimizes key supply chain processes for enhanced efficiency. It enables businesses to manage procurement, production, and distribution seamlessly, ensuring streamlined operations.",
      ],
      link: "/sap-scm",
    },
    {
      title: "SAP ARIBA",
      points: [
        "SAP Ariba is a cloud-based procurement platform by SAP that streamlines and automates procurement processes, enhancing collaboration between buyers and suppliers. IT Accurate Institute Mumbai is an educational institution specializing in SAP training and certification.",
      ],
      link: "/sap-ariba",
    },
    {
      title: "SAP PP",
      points: [
        "SAP PP (Production Planning) is an SAP module that helps organizations manage and optimize their manufacturing processes, from planning and scheduling to execution. It integrates various business functions to streamline production.",
      ],
      link: "/sap-pp",
    },
    {
      title: "SAP SD",
      points: [
        "SAP SD (Sales and Distribution) is a module in SAP ERP that manages sales and customer service processes. It covers order-to-cash processes, including sales order processing, pricing, delivery, and billing. SAP SD to equip individuals with skills.",
      ],
      link: "/sap-sd",
    },
    {
      title: "SAP FIORI",
      points: [
        "SAP Fiori is a user experience (UX) design approach and set of design principles that enhance the usability and functionality of SAP applications. It provides a modern, responsive, and personalized user interface for a seamless and intuitive user experience in the SAP environment.",
      ],
      link: "/sap-fiori",
    },
    {
      title: "SAP QM",
      points: [
        "SAP QM (Quality Management) is a module in SAP ERP that facilitates quality control and assurance processes in manufacturing and production. It helps organizations manage quality planning, inspection, and certification, ensuring products meet specified standards and many more.",
      ],
      link: "/sap-qm",
    },
    {
      title: "SAP PM",
      points: [
        "SAP PM (Plant Maintenance) is a module in SAP ERP that helps organizations effectively manage and maintain their assets, equipment, and facilities. It covers processes such as preventive maintenance, work orders, and equipment tracking to optimize asset performance.",
      ],
      link: "/sap-pm",
    },
    {
      title: "SAP WM & EWM",
      points: [
        "SAP WM (Warehouse Management) focuses on efficient inventory management within a warehouse, while SAP EWM (Extended Warehouse Management) extends these capabilities with advanced features like labour management and slotting optimization for operations.",
      ],
      link: "/sap-wm-ewm",
    },
    {
      title: "SAP LE & SL",
      points: [
        "SAP LE (Logistics Execution) is a module that manages and optimizes warehouse and transportation processes, while SAP SL (Slotting) focuses on optimizing storage space and picking efficiency within a warehouse. Both modules contribute to streamlining supply chain operations in SAP systems.",
      ],
      link: "/sap-le-sl",
    },
  ];

  const syllabus = [
    {
      title: "Warehouse Management (WM)",
      subpoints: [
        "Master data in WM",
        "Goods movements",
        "Transfer orders",
        "Inventory management in WM",
      ],
    },
    {
      title: "Inventory Management",
      subpoints: [
        "Goods movements",
        "Physical inventory",
        "Special stock types",
        "Batch management",
      ],
    },
    {
      title: "Shipping",
      subpoints: [
        "Shipping processes",
        "Packing",
        "Shipping point determination",
        "Transportation planning",
      ],
    },
    {
      title: "Handling Unit Management",
      subpoints: [
        "Handling unit creation and management",
        "Handling unit types",
        "Packing and unpacking",
      ],
    },
    {
      title: "Integration with Other SAP Modules",
      subpoints: [
        "Integration with Sales and Distribution (SD)",
        "Integration with Materials Management (MM)",
        "Integration with Production Planning (PP)",
      ],
    },
    {
      title: "Integration with Other SAP Modules",
      subpoints: [
        "Integration with Finance (FI) and Controlling (CO)",
        "Integration with Quality Management (QM)",
      ],
    },
  ];

  const faqs = [
    {
      question: "What is SAP LE?",
      answer:
        "SAP LE (Logistics Execution) is a component of SAP ERP that focuses on the efficient management of logistics processes such as warehousing, transportation, and order fulfillment.",
    },
    {
      question: "Key features of SAP LE?",
      answer:
        "SAP LE includes features like warehouse management, transportation management, and handling unit management to optimize logistics operations.",
    },
    {
      question: "Difference between SAP LE and SAP SL?",
      answer:
        "SAP LE is part of the larger SAP ERP suite, specifically focusing on logistics execution. SAP SL (Simple Logistics) is a component of SAP S/4HANA, providing a simplified and integrated approach to logistics processes within the S/4HANA environment.",
    },
    {
      question: "Advantages of using SAP LE?",
      answer:
        "SAP LE helps organizations streamline their logistics processes, reduce costs, improve inventory accuracy, enhance order fulfillment, and optimize overall supply chain management.",
    },
    {
      question: "Integration of SAP LE with other SAP modules?",
      answer:
        "SAP LE integrates with various SAP modules like SAP MM (Materials Management), SAP SD (Sales and Distribution), and SAP PP (Production Planning) to ensure seamless flow of information across different business functions.",
    },
    {
      question: "Common challenges in SAP LE implementation?",
      answer:
        "Challenges may include data migration issues, user training requirements, customization complexities, and the need for effective change management during the implementation process.",
    },
    {
      question: "How does SAP SL simplify logistics processes?",
      answer:
        "SAP SL, as part of SAP S/4HANA, simplifies logistics processes by providing a unified data model, real-time analytics, and a modern user interface, enabling businesses to make quicker and more informed decisions.",
    },
    {
      question: "Is SAP LE suitable for small businesses?",
      answer:
        "While SAP LE is a comprehensive solution, it may be more suitable for larger enterprises. Small businesses might explore scaled-down alternatives or consider specific SAP solutions tailored to their needs.",
    },
    {
      question: "Latest trends in SAP LE and SL?",
      answer:
        "Trends include the adoption of cloud-based solutions, increased focus on real-time analytics, incorporation of IoT (Internet of Things) for enhanced visibility, and the continuous development of AI-driven features to optimize logistics processes.",
    },
  ];
  const whatsData = [
    {
      title: "About SAP LE & SL Training Course",
      points: [
        "SAP LE (Logistics Execution): Manages end-to-end logistics processes, including warehouse management, transportation, and order fulfillment.",
        "SAP SL streamlines supplier onboarding, qualification, and collaboration to enhance the procurement process.",
        "LE integrates with other SAP modules like MM (Materials Management) and SD (Sales and Distribution) for seamless business processes.",
        "SL ensures compliance with regulations and standards throughout the supplier relationship.",
        "LE optimizes inventory management, reducing costs and improving order fulfillment efficiency.",
        "SL centralizes supplier data, facilitating better decision-making and risk management.",
        "LE supports real-time tracking and monitoring of goods in transit.",
        "IT Accurate provides SAP MM training in offline and online mode, starting from end user, consulting, implementation, and support, with real-time SAP project-based training.",
        "IT background, non-IT background, freshers, and experienced individuals can start their career in SAP irrespective of their background.",
        "IT Accurate is the best training institute in Mumbai with 100% job opportunities.",
      ],
    },
  ];

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // const imageList = [
  //     { Image: dev1, alt: "Award 1" },
  //     { Image: dev2, alt: "Award 2" },
  //     { Image: dev3, alt: "Award 3" },
  //     { Image: dev4, alt: "Award 4" },
  //     { Image: dev5, alt: "Award 5" },
  //     { Image: dev6, alt: "Award 6" },
  //     { Image: dev7, alt: "Award 7" },
  //     { Image: dev8, alt: "Award 8" },
  // ];

  // const reviewList = [
  //     { Image: rev1, alt: "rev1" },
  //     { Image: rev2, alt: "rev1" },
  //     { Image: rev3, alt: "rev1" },
  //     { Image: rev4, alt: "rev1" },
  //     { Image: rev5, alt: "rev1" },
  // ]
  const keyFeatures = [
    { text: "Limited Students Batch", icon: "fa-users" },
    { text: "Personalised Attention", icon: "fa-user-check" },
    { text: "Highly Qualified Teachers", icon: "fa-chalkboard-teacher" },
    { text: "Flexible Batch Timings", icon: "fa-calendar-alt" },
    { text: "Interactive Learning", icon: "fa-comments" },
    { text: "Live Projects", icon: "fa-laptop-code" },
    { text: "Career Support", icon: "fa-briefcase" },
    { text: "Job Oriented Training", icon: "fa-graduation-cap" },
  ];
  return (
    <div className="devops-page">
      {/* <SideDropdown roadmapData={modulesData} /> */}
      <div className="content-container">
        <div className="devops-circle devops-circle-bottom-left"></div>
        <div className="left-content">
          <h1>Best SAP LE&SL Training</h1>
          <p>
            IT Accurate offers the premier SAP LE & SL training in Mumbai for
            both freshers and experienced candidates, providing expert guidance
            and a 100% job placement guarantee. Our SAP training course
            thoroughly covers all technical topics, ensuring students gain
            real-time, hands-on experience.
          </p>
          <br />
          <p>
            Our practical, job-oriented training program not only awards you a
            certificate but also equips you with knowledge equivalent to
            approximately 2+ years of industry experience. We respect your time
            as much as our own, which is why we’ve designed our SAP LE & SL
            training with S/4 HANA to be completed in just 4 months.
          </p>

          <ul className="points-list">
            <li>End user 1 month (ECC / S4 HANA)</li>
            <li>Configuration 2 months(ECC/S4 HANA)</li>
            <li>Project 1 month</li>
          </ul>
          <h3>All the topics will be covered in detail and also include:</h3>
          <ul className="points-list">
            <li>
              <FontAwesomeIcon icon={faFileAlt} /> Resume preparation
            </li>
            <li>
              <FontAwesomeIcon icon={faChalkboardTeacher} /> Interview practice
            </li>
            <li>
              <FontAwesomeIcon icon={faBriefcase} /> 6-months internship
            </li>
            <li>
              <FontAwesomeIcon icon={faCheckCircle} /> 100% job opportunities
              guaranteed program
            </li>
          </ul>
        </div>
        <div className="form-contact">
          <ContactForm title="Get in Touch" submitText="Submit" />
        </div>
      </div>
      {/* <div className="video-section">
                <div className="video-container" controls ref={videoRef}></div>
            </div> */}
             <StickyNavbar name="SapLeSL" />
      <WhatsDevOps whatsData={whatsData} />

      <Roadmap heading="Roadmap to Learn SAP LE&SL" roadmapData={roadmapData} />
      <Syllabus heading="SAP LE&SL Training Syllabus" Syllabus={syllabus} />
      <KeyFeatures heading="SAP LE&SL Training" keyFeatures={keyFeatures} />

      <section id="certification" className="devops-certification-section">
        <h2 className="certification-heading">SAP LE&SL CERTIFICATION</h2>
        <div className="certification-content">
          <div className="certification-points">
            <ul>
              <li>
                IT Accurate training certification will serve as proof that the
                courses were completed by IT Accurate.
              </li>
              <li>
                The SAP S/4HANA certification offered by IT Accurate will equip
                you with valuable skills, enhancing your competitiveness in the
                job market.
              </li>
              <li>
                IT Accurate provides comprehensive guidance for your SAP S/4HANA
                global certification, ensuring a 100% passing guarantee in
                examinations such as SAP Certification, SAP S/4HANA Platform
                Development Certification, and various other global exams.
              </li>
            </ul>
          </div>
          <div className="certification-image">
            <img src={certificateImg} alt="DevOps Certification" />
          </div>
        </div>
      </section>

      <Roadmap heading="SAP modules" roadmapData={modulesData} />

      {/* devops project Section
            <div className="slider-container">
                <h2 className="slider-heading">Devops Project</h2>
                <RegularSlider sliderSettings={sliderSettings} images={imageList} />
            </div> */}
      <CourseOpportunities pageName="SAP_LESL" />
      <Faq Faqs={faqs} />

      {/* <div className="slider-container">
                <h2 className="slider-heading">SAP ABAP Training courses review</h2>
                <RegularSlider sliderSettings={sliderSettings} images={reviewList} />
            </div> */}

      <MarqueeLogos />
    </div>
  );
};

export default SAPLESL;
