import { useEffect, useState } from "react";
import "../css/StickyNavbar.css";

// StickyNavbar component accepts 'name' prop
const StickyNavbar = ({ name }) => {
  const [activeSection, setActiveSection] = useState("");

  // Define sections with dynamic labels using the 'name' prop
  const sections = [
    { id: "whats-devops", label: `What is ${name}?` }, // Use the 'name' prop dynamically
    { id: "roadmap", label: "Roadmap" },
    { id: "key-features", label: "Key Features" },
    { id: "syllabus", label: "Syllabus" },
    { id: "certification", label: "Certification" },
    { id: "faq", label: "FAQ" },
  ];

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY + 100; // Adjust offset as needed
      sections.forEach(({ id }) => {
        const sectionElement = document.getElementById(id);
        if (sectionElement) {
          const { offsetTop, offsetHeight } = sectionElement;
          if (
            scrollPosition >= offsetTop &&
            scrollPosition < offsetTop + offsetHeight
          ) {
            setActiveSection(id);
          }
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []); // Effect only runs once, since there are no dependencies

  return (
    <div className="sticky-navbar">
      {sections.map(({ id, label }) => (
        <a
          key={id}
          href={`#${id}`}
          className={activeSection === id ? "active" : ""}
        >
          {label}
        </a>
      ))}
    </div>
  );
};

export default StickyNavbar;
