import React from "react";
import coursesData from "../PlacementOpportunites";
import { useModal } from "../context/ModelContext";
import DialogContact from "./DialogContact";

const CourseOpportunities = ({ pageName }) => {
    const course = coursesData[pageName];
    const { openModal } = useModal();

    const handleClick =()=>{
        openModal(<DialogContact />);
    }

    if (!course) {
        return <p className="text-center text-gray-600">No data available for this page.</p>;
    }

    return (
        <div className="p-6 bg-gray-50">
            <h1
                className="md:text-[3rem] font-bold text-center text-gray-800"
                style={{ fontStyle:'normal',fontFamily: "Poppins, sans-serif", fontWeight: 600 }}
            >
                Job Openings in{" "}
                <span className="bg-[#D1E1FF] p-2 rounded-lg">{course.title}</span>
            </h1>

            <p className="mt-5 text-center text-gray-700">{course.description}</p>

            <div className="mt-6 flex flex-col lg:flex-row gap-2">
                    {/* Salaries Section (60%) */}
                    <div className="flex-1 basis-3/5 bg-blue-100 p-6 rounded-lg">
                    <h2 className="text-3xl text-blue-800">Salary Package in {pageName}</h2>
                    <table className="mt-4 w-full text-left border-collapse">
                        <thead>
                            <tr>
                                <th className="px-3 py-2 text-sm text-blue-600 border-b">Job Title</th>
                                <th className="px-3 py-2 text-sm text-blue-600 border-b">Salary Range</th>
                            </tr>
                        </thead>
                        <tbody>
                            {course.salaries.map((salary, index) => (
                                <tr key={index} className="border-b border-gray-400">
                                    <td className="py-3 text-gray-700">{salary.role}</td>
                                    <td className="py-3 text-gray-700">{salary.range}</td>
                                </tr>
                            ))}
                        </tbody>
                    
                    </table>
                    <button onClick={handleClick} className="mt-6 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700">
                    Contact Our Career Counselor
                    </button>
                </div>
                {/* Roles Section (40%) */}
                <div className="flex-1 basis-2/5 bg-blue-300 p-4">
                    <h2 className="text-2xl text-blue-900">Opportunities to Work as a</h2>
                    <ul className="mt-4 flex flex-wrap gap-3">
                        {course.roles.map((role, index) => (
                            <li
                                key={index}
                                className="inline-block px-3 py-3 bg-blue-100 text-blue-900"
                            >
                                {role}
                            </li>
                        ))}
                    </ul>

                    <h2 className="mt-6 text-2xl text-blue-900">
                    Jobs Trending in Demand
                    </h2>
                    <ul className="mt-4 flex flex-wrap gap-3">
                        {course.upcomingRoles.map((role, index) => (
                            <li
                                key={index}
                                className="inline-block px-3 py-2 bg-blue-100 text-blue-900"
                            >
                                {role}
                            </li>
                        ))}
                    </ul>
                  
                </div>

               
            </div>
           
        </div>
    );
};

export default CourseOpportunities;
