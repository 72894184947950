import React, { useState } from "react";
import {
  FaPhoneAlt,
  FaChalkboardTeacher,
  FaBriefcase,
} from "react-icons/fa";
import "../css/ContactForm.css";
import { useModal } from "../context/ModelContext";
import DialogContact from "./DialogContact";

const ContactCard = () => {
  const [trainingType, setTrainingType] = useState("online");

  const { openModal } = useModal();

  const handleToggle = (type) => {
    setTrainingType(type);
  };

  const handleEnrollClick = () => {
    openModal(<DialogContact />);
  };

  return (
    <div className="contact-card-container">
      {/* Training Details */}
      <div className="training-details">
        <FaChalkboardTeacher className="training-icon" />
        <h2 className="training-title">Live Instructor LED Online Training</h2>
        <p className="training-subtitle">Learn from Certified Experts</p>
        <div className="job-assistance">
          <FaBriefcase className="assistance-icon" />
          <p>100% Job Assistance & Placement</p>
        </div>
      </div>

      {/* Training Type Buttons */}
      <div className="training-type-buttons">
        <button
          className={`training-type-btn ${trainingType === "online" ? "active" : ""}`}
          onClick={() => handleToggle("online")}
        >
          Online Training
        </button>
        <button
          className={`training-type-btn ${trainingType === "one-to-one" ? "active" : ""}`}
          onClick={() => handleToggle("one-to-one")}
        >
        Offline Training
        </button>
      </div>

      {/* Enroll Button */}
      <div className="action-buttons">
        <button className="enroll-btn" onClick={handleEnrollClick}>
          Enroll Now
        </button>
      </div>

      {/* Query Section */}
      <div className="queries-section">
        <h3 className="queries-heading">Have Queries? Ask our Experts</h3>
        <p className="contact-number">
          <FaPhoneAlt className="phone-icon" />
          +91-7972 849 125
        </p>
        <p className="availability">Available 24x7 for your queries</p>
      </div>
    </div>
  );
};

export default ContactCard;
