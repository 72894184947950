import React from "react";
import "../css/TrainerDetails.css";

const TrainerDetails = () => {
  return (
    <div className="trainer-container">
      <h2 className="trainer-heading">About Our Trainer</h2>
      <ul className="trainer-list">
        <li>
          <strong>Our training programs</strong> are conducted by certified professionals
          with 7+ years of experience in their respective domains and who are
          currently working with top MNCs.
        </li>
        <li>
          All our trainers are domain experts who work on live projects and use these
          projects during training sessions.
        </li>
        <li>
          Our trainers are employed by companies like Cognizant, Dell, Infosys,
          IBM, L&T InfoTech, TCS, HCL Technologies, and more.
        </li>
        <li>
          Trainers also assist candidates in securing placements in their respective
          companies through employee referrals or internal hiring processes.
        </li>
        <li>
          Our trainers are industry experts and subject specialists who excel in
          providing <strong>the best training</strong> to students.
        </li>
        <li>
          We have received several prestigious awards for training excellence
          from recognized IT organizations.
        </li>
      </ul>
    </div>
  );
};

export default TrainerDetails;
