
// Utility function to create minisubmenu items (individual modules or options)
const createMiniSubMenuItem = (name, path = "") => {
  return { name, path };  // Add path property here
};

// Utility function to create submenus with a list of minisubmenus (modules) directly
const createSubMenu = (name, desc, logoUrl, minisubMenus = [], path = "") => {
  return {
    name,
    desc,
    logoUrl,
    path, // Add path property here
    minisubMenus,
  };
};

// Main menus configuration with submenus and minisubmenus
export const Menus = [
  {
    name: "Course",
    subMenu: [
      //first shoud be SAP
      // createSubMenu(
      //   "SAP",
      //   "Enterprise solutions",
      //   "",
      //   [
      //     createMiniSubMenuItem("SAP MM", "/sap-mm"),
      //     createMiniSubMenuItem("SAP HCM", "/sap-hcm"),
      //     createMiniSubMenuItem("SAP ABAP", "/sap-abap"),
      //     createMiniSubMenuItem("SAP BASIS", "/sap-basis"),
      //     createMiniSubMenuItem("SAP SCM", "/sap-scm"),
      //     createMiniSubMenuItem("SAP ARIBA", "/sap-ariba"),
      //     createMiniSubMenuItem("SAP SD", "/sap-sd"),
      //     createMiniSubMenuItem("SAP PP", "/sap-pp"),
      //     createMiniSubMenuItem("SAP PM", "/sap-pm"),
      //     createMiniSubMenuItem("SAP QM", "/sap-qm"),
      //     createMiniSubMenuItem("SAP LE&SL", "/sap-lesl"),
      //     createMiniSubMenuItem("SAP WM&EWM", "/sap-wmewm"),
      //     createMiniSubMenuItem("SAP FICO", "/sap-fico"),
      //     createMiniSubMenuItem("SAP FIORI", "/sap-fiori"),
      //   ],
      //   "/sap" // Path for the SAP submenu
      // ),
      createSubMenu("DevOps", "Accelerate Delivery", "https://admin.tops-int.com/storage/category/icons/98024.png", [], "/devops"),
      //third Salesforce
      createSubMenu(
        "Salesforce",
        "CRM Solutions",
        "https://admin.tops-int.com/storage/course/icons/resized/12332.png",
        [
          createMiniSubMenuItem("Salesforce Admin", "/salesforce-admin"),
          createMiniSubMenuItem("Salesforce Development", "/salesforce-development"),
          createMiniSubMenuItem("Salesforce LWC & Integration", "/salesforce-lwc"),
          createMiniSubMenuItem("Salesforce Marketing Cloud", "/salesforce-marketing"),
        ],
        "/salesforce" // Path for the Salesforce submenu
      ),
      createSubMenu("AWS", "Cloud Power", "https://download.logo.wine/logo/Amazon_Web_Services/Amazon_Web_Services-Logo.wine.png", [], "/aws"),
      //fifth DA
      createSubMenu(
        "Data Science AI & ML", // Main Menu Name
        "Intelligent Decisions", // SubMenu Description
        "https://cdn-icons-png.flaticon.com/512/4824/4824797.png", // logoUrl
        [
          createMiniSubMenuItem("Machine Learning", "/ml"),
          createMiniSubMenuItem("AI & ML", "/aiml"),
          createMiniSubMenuItem("Data Analytics", "/da"),
        ],
        "/ds" // Path for the Cloud Technologies submenu
      ),
      createSubMenu("Full Stack Development", "End-to-End", "https://admin.tops-int.com/storage/course/icons/resized/36258.png", [], "/fsd"),
      createSubMenu("Business Analytics", "Data Insights", "https://w7.pngwing.com/pngs/46/603/png-transparent-pegasystems-business-intelligence-interview-information-business-analytics-business-retail-people-logo.png", [], "/ba"),
      createSubMenu("Python", "Future Coding", "https://admin.tops-int.com/storage/course/icons/resized/47038.png", [], "/python"),
      createSubMenu(
        "Data Visualization",
        "training",
        "https://admin.tops-int.com/storage/course/icons/resized/63243.png",
        [
          createMiniSubMenuItem("Tableau", "/tableau"),
          createMiniSubMenuItem("Advanced Excel", "/excel"),
          createMiniSubMenuItem("Power BI", "/powerbi"),
        ],
        "/powerbi" // Path for the Salesforce submenu
      ),
    ],
    gridCols: 2,
  },
  {
    name: "About",
    path: "/about",
  },
  {
    name: "Blog",
    path: "/blog", // Add path for the Blog menu
  },
  {
    name: "Placements",
    path: "/placed", // Add path for the Blog menu
  },
  // {
  //   name: "Carrer",
  //   path: "/pricing", // Add path for the Pricing menu
  // },
  {
    name: "Contact",
    path: "/contact", // Add path for the Contact menu
  },
];
