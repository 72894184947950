import React from 'react';
import { useModal } from '../context/ModelContext';

const GlobalModal = () => {
  const { isModalOpen, closeModal, modalContent } = useModal();

  if (!isModalOpen) return null;

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-3xl mx-4 sm:mx-8 md:mx-16 relative">
        <button
          onClick={closeModal}
          className="absolute top-2 right-2 text-gray-600 hover:text-black"
        >
          ✖
        </button>
        <div className="max-h-[80vh] overflow-y-auto scroll-smooth">{modalContent}</div>
      </div>
    </div>
  );
};

export default GlobalModal;
