import React from "react";
import "../css/Hero.css"; // Ensure you link your updated CSS here
import ContactForm from "./ContactForm";
import { Link } from "react-router-dom";
const Hero = () => {
  return (
    <>
      {/* Hero Section */}
      <section className="hero">
        <div className="hero-container">
          <div className="row">
            <h1>Leading Education and Career Expertise</h1>
            <p>
              Transform your future with comprehensive education and
              career-driven programs. IT Accurate ensures hands-on training,
              industry connections, and tailored courses for every ambition.
            </p>
            <div className="button-group">
              <button className="inquiry-btn">
                <Link
                  to="/contact"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  INQUIRY NOW <i className="fa fa-long-arrow-alt-right"></i>
                </Link>
              </button>
              <button className="course-btn">
                <Link
                  to="/about"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  VIEW COURSE <i className="fa fa-long-arrow-alt-right"></i>
                </Link>
              </button>
            </div>
          </div>
        </div>
      </section>

      {/* Stats Section */}
      <section className="stats">
        <div className="hero-container">
          <div className="stats-box row">
            <div className="stat-item">
              <i className="fa fa-users" style={{ color: "rgb(28, 152, 237)" }}></i>
              <h3>1 Lac+</h3>
              <p>Students Placed</p>
            </div>

            <div className="stat-item">
              <i className="fa fa-book" style={{ color: "rgb(28, 152, 237)" }}></i>
              <h3 >50+</h3>
              <p>Industry Courses</p>
            </div>
            <div className="stat-item">
              <i className="fa fa-graduation-cap" style={{ color: "rgb(28, 152, 237)" }}></i>
              <h3>460,000+</h3>
              <p>Trained</p>
            </div>
            <div className="stat-item">
              <i className="fa fa-laptop" style={{ color: "rgb(28, 152, 237)" }}></i>
              <h3>7,000+</h3>
              <p>Live Classes Every Month</p>
            </div>
            <div className="stat-item">
              <i className="fa fa-chart-line" style={{ color: "rgb(28, 152, 237)" }}></i>
              <h3>87%</h3>
              <p>Reported Career Benefits</p>
            </div>
            <div className="stat-item">
              <i className="fa fa-handshake" style={{ color: "rgb(28, 152, 237)" }}></i>
              <h3>900+</h3>
              <p>Corporate Partners</p>
            </div>
          </div>
        </div>
      </section>

      {/* Job Assistance Section */}
      <section className="job-assistance">
        <div className="hero-container">
          <div className="content">
            <div className="text">
              <h2>
                Get 100% Job Assistance & Get Placed in Your Dream Company
              </h2>
              <ul className="hero-ul">
                <li>
                  <i className="fa fa-briefcase" style={{ color: "rgb(28, 152, 237)" }}></i> Job Assistance
                </li>
                <li>
                  <i className="fa fa-handshake" style={{ color: "rgb(28, 152, 237)" }}></i> 3000+ Companies Tie-Ups
                </li>
                <li>
                  <i className="fa fa-chalkboard-teacher" style={{ color: "rgb(28, 152, 237)" }}></i> Expert
                  Instructors
                </li>
                <li>
                  <i className="fa fa-lightbulb" style={{ color: "rgb(28, 152, 237)" }}></i> Project-Based Learning
                </li>
                <li>
                  <i className="fa fa-user-graduate" style={{ color: "rgb(28, 152, 237)" }}></i> Internship
                  Opportunities
                </li>
                <li>
                  <i className="fa fa-certificate" style={{ color: "rgb(28, 152, 237)" }}></i> Certification Programs
                </li>
              </ul>

              <button>
                <a className="job-assistance-btn" href="/contact">
                  Enter Your Details Now
                </a>
              </button>
            </div>
            <div className="form">
              <ContactForm
                className="form-contact"
                title="Get in Touch"
                submitText="Submit"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Hero;
