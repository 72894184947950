import React, { useState } from 'react';
import '../css/whyus.css';
import img1 from '../assets/it accurate logo.png';

const WhyUs = () => {
  const [dialogueOpen, setDialogueOpen] = useState(false);
  const [currentCard, setCurrentCard] = useState(null);

  const cardsData = [
    { image: 'https://cdn-icons-png.freepik.com/256/3048/3048708.png?semt=ais_hybrid', title: 'Experienced Instructor', info: 'Our instructors are certified professionals with years of experience.' },
    { image: 'https://cdn-icons-png.flaticon.com/512/11421/11421728.png', title: 'Career Development', info: 'We offer career development resources to help you grow.' },
    { image: 'https://cdn-icons-png.flaticon.com/512/809/809439.png', title: 'One-to-One Training', info: 'Receive personalized training tailored to your needs.' },
    { image: 'https://cdn-icons-png.flaticon.com/512/2002/2002253.png', title: 'Flexible Schedule', info: 'Choose a schedule that fits your lifestyle.' },
    { image: 'https://cdn-icons-png.flaticon.com/256/3476/3476346.png', title: 'Interview Preparation', info: 'We provide guidance to prepare you for job interviews.' },
    { image: 'https://cdn-icons-png.flaticon.com/512/13415/13415922.png', title: 'Job Guarantee', info: 'We Provide guaranteed job opportunites for every candidates.' },
    { image: 'https://cdn-icons-png.flaticon.com/512/4199/4199482.png', title: 'Certification', info: 'Earn certificates upon course completion.' },
    { image: 'https://cdn-icons-png.flaticon.com/512/1668/1668977.png', title: 'Free WiFi', info: 'Enjoy free WiFi access during your training.' },
  ];

  const handleReadMore = (index) => {
    setCurrentCard(cardsData[index]);
    setDialogueOpen(true);
  };

  const closeDialogue = () => {
    setDialogueOpen(false);
  };

  return (
    <div className="why-us">
      <h2>Why IT Accurate?</h2>
      <div className="cards-container">
        {cardsData.map((card, index) => (
          <div className="card" key={index} style={{ backgroundColor: getCardColor(index) }}>
            <img src={card.image} alt={card.title} className="card-image" />
            <div className="title">{card.title}</div>
            <button className="read-more" onClick={() => handleReadMore(index)}>Read More</button>
          </div>
        ))}
      </div>

      {dialogueOpen && (
        <div className="dialogue-overlay">
          <div className="dialogue-box">
            <img src={img1} alt="Logo" className="dialogue-logo" />
            <h3>{currentCard.title}</h3>
            <p>{currentCard.info}</p>
            <button className="close-dialogue" onClick={closeDialogue}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

// Function to return different background colors
const getCardColor = (index) => {
  const colors = ['#E6E096', '#F396CD', '#CFD4FF', '#ADD8E6', '#FFC7BC', '#E6E6FA', '#F0E68C', '#D8BFD8'];
  return colors[index % colors.length];
};

export default WhyUs;
