const courseData = {
    salesforce: {
      title: "Salesforce Training: Learn, Practice, and Get Hired",
      points: [
        "100% Guaranteed Placement Assistance for Salesforce enthusiasts.",
        "Master Salesforce Admin skills with real-world industry insights.",
        "Experienced Salesforce-certified trainers and state-of-the-art labs.",
        "Comprehensive Salesforce Administrator Certification Guidance.",
        "Hands-on practical training with real-time project scenarios.",
        "Our in-depth Salesforce course aligns with current job market demands.",
        "Resume building and interview preparation tailored for Salesforce roles.",
        "Explore key Salesforce concepts: Salesforce.com, Cloud Computing, User and Data Management, Security, Automation, Customization (Fields, Reports, Dashboards), and much more.",
        "Kickstart your Salesforce career with salaries ranging from ₹4–10 LPA in just 60 days!",
      ],
    },
    salesforceAdmin: {
      title: "Salesforce Admin Training: Become a Certified Admin",
      points: [
        "Learn Salesforce Admin concepts from scratch.",
        "Understand data and user management, roles, and permissions.",
        "Gain expertise in Salesforce security and automation.",
        "Build real-world skills to implement business requirements.",
        "Get certified and increase your earning potential.",
      ],
    },
    salesforceDevlopment: {
      title: "Salesforce Development Training: Build Advanced Solutions",
      points: [
        "Learn Apex programming for custom business logic.",
        "Master Visualforce and Lightning Web Components (LWC) for UI development.",
        "Understand Salesforce data model and custom object creation.",
        "Integrate Salesforce with external systems using REST and SOAP APIs.",
        "Work on real-world Salesforce development projects.",
        "Gain expertise in Salesforce deployment and version control.",
        "Prepare for Salesforce Platform Developer certifications.",
        "Build scalable and efficient solutions for various industries.",
      ],
    },
    development: {
      title: "Full Stack Development Training: Code Your Future",
      points: [
        "Master front-end and back-end development skills.",
        "Learn popular technologies like React, Node.js, and MongoDB.",
        "Build fully functional web applications from scratch.",
        "Work on real-world projects to strengthen your portfolio.",
        "Prepare for a career in full-stack development with job-ready skills.",
      ],
    },
    salesLwcIntegration: {
      title: "Salesforce LWC and Integration Training",
      points: [
        "Learn Lightning Web Components (LWC) from scratch.",
        "Master integration concepts with REST and SOAP APIs.",
        "Hands-on experience with real-world Salesforce projects.",
        "Understand event handling and custom components in Salesforce.",
        "Become proficient in advanced Salesforce development techniques.",
      ],
    },
    salesforceMarketingCloud: {
      title: "Salesforce Marketing Cloud Training",
      points: [
        "Learn Salesforce Marketing Cloud fundamentals and tools.",
        "Master email, social media, and customer journey campaigns.",
        "Work on hands-on projects to apply marketing strategies.",
        "Understand data management and audience segmentation.",
        "Prepare for Salesforce Marketing Cloud certifications.",
      ],
    },
    dataScience: {
      title: "Data Science Training: Learn to Analyze and Predict",
      points: [
        "Master Python, R, and Machine Learning techniques.",
        "Work on real-world data science projects.",
        "Become proficient in Data Cleaning, Visualization, and Modeling.",
        "Learn advanced concepts such as NLP and Deep Learning.",
        "Build a career in one of the fastest-growing fields.",
      ],
    },
    dataAnalytics: {
      title: "Data Analytics Training: Unlock Insights from Data",
      points: [
        "Learn data cleaning, visualization, and reporting techniques.",
        "Master tools like Excel, Tableau, and Power BI.",
        "Work on real-world data analysis projects.",
        "Understand predictive analytics and business intelligence.",
        "Prepare for a career in data-driven decision-making.",
      ],
    },
    aiMl: {
      title: "AI and ML Training: Build Intelligent Systems",
      points: [
        "Master AI and Machine Learning algorithms.",
        "Learn Python, TensorFlow, and PyTorch for AI development.",
        "Work on projects like image recognition and NLP.",
        "Understand supervised and unsupervised learning techniques.",
        "Become a skilled AI/ML professional ready for industry demands.",
      ],
    },
    machineLearning: {
      title: "Machine Learning Training: Transform Data into Predictions",
      points: [
        "Master Machine Learning algorithms and techniques.",
        "Learn data preprocessing, feature engineering, and model building.",
        "Work on real-world projects in various industries.",
        "Understand deployment of machine learning models.",
        "Prepare for a career in the AI-driven world.",
      ],
    },
    devops: {
      title: "DevOps Training: Bridge Development and Operations",
      points: [
        "Learn CI/CD pipelines, Docker, Kubernetes, and Jenkins.",
        "Master infrastructure as code with tools like Terraform and Ansible.",
        "Gain hands-on experience in cloud platforms like AWS and Azure.",
        "Work on real-world DevOps projects to build job-ready skills.",
        "Prepare for DevOps certifications to boost your career.",
      ],
    },
    businessAnalytics: {
      title: "Business Analytics Training: Transform Data into Decisions",
      points: [
        "Learn tools like Excel, Tableau, and Power BI for analytics.",
        "Understand statistical and predictive modeling techniques.",
        "Work on real-world business analytics projects.",
        "Master data-driven decision-making skills.",
        "Build a career in business analytics with in-demand skills.",
      ],
    },
    aws: {
      title: "AWS Training: Master Cloud Computing",
      points: [
        "Learn AWS services like EC2, S3, and RDS.",
        "Gain expertise in cloud architecture and deployment.",
        "Work on real-world cloud computing projects.",
        "Prepare for AWS certifications like Solutions Architect.",
        "Build a career in the fast-growing cloud computing industry.",
      ],
    },
    python: {
      title: "Python Training: Learn to Code with Simplicity",
      points: [
        "Master Python programming from basics to advanced concepts.",
        "Learn libraries like NumPy, Pandas, and Matplotlib.",
        "Work on real-world projects to solidify your skills.",
        "Understand scripting, automation, and web development.",
        "Prepare for roles in data science, AI, and software development.",
      ],
    },
    tableau: {
      title: "Tableau Training: Visualize Your Data",
      points: [
        "Learn Tableau basics and advanced visualization techniques.",
        "Master dashboards, data blending, and story creation.",
        "Work on real-world data visualization projects.",
        "Prepare for Tableau certifications to advance your career.",
        "Become proficient in communicating insights through data.",
      ],
    },
    powerbi: {
      title: "Power BI Training: Data Visualization Made Simple",
      points: [
        "Master Power BI for interactive data analysis.",
        "Learn data modeling, DAX, and report creation.",
        "Work on hands-on projects for real-world scenarios.",
        "Understand business intelligence and reporting techniques.",
        "Prepare for roles in data analytics and business intelligence.",
      ],
    },
    advancedExcel: {
      title: "Advanced Excel Training: Master Spreadsheet Skills",
      points: [
        "Learn advanced Excel formulas, functions, and macros.",
        "Master data analysis and visualization techniques.",
        "Work on real-world Excel projects to boost your skills.",
        "Understand pivot tables, data cleaning, and reporting.",
        "Prepare for roles in business and financial analysis.",
      ],
    },
  };
  
  export default courseData;
  