// PrivacyPolicy.js
import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div style={{ padding: '20px', lineHeight: '1.6' , marginTop:'4rem' }}>
      <h1>IT Accurate Training - Privacy Policy</h1> {/* Heading added */}
      
      <p>
        At IT Accurate Training, we are committed to ensuring your personal information is protected at all times. This privacy policy outlines what data IT Accurate Training collects, uses, and processes about you. It also explains your data rights and how you can contact us if you have any queries. We hold the right to alter this privacy policy at our discretion and encourage you to check this page from time to time to be aware of any changes.
      </p>

      <h2>To whom does this policy apply?</h2>
      <p>
        This privacy policy statement applies to all individuals who interact with our website, subscribe to any of our services or products, request information, or use chat support.
      </p>

      <h2>What data do we collect?</h2>
      <h3>Parts of the Services</h3>
      <p>
        We let you interact with other users or share content publicly, including by posting reviews, asking questions, sending messages, or posting photos. Such content may be publicly viewable depending on where it is posted.
      </p>

      <h3>Educational Content Data</h3>
      <p>
        When you access content, we collect data on courses, assignments, practice tests, quizzes you’ve started and completed, content purchases, credits, and exchanges with instructors and students.
      </p>

      <h3>Student Payment Data</h3>
      <p>
        If you make purchases, we collect data necessary to process your order, such as your name and billing address. Payment data, including card information, is processed by our payment service providers and not stored directly by IT Accurate Training.
      </p>

      <h2>How do we collect data?</h2>
      <p>
        - When you register: This includes your name, email address, city, and phone number.
        - When registering for a batch: This includes details such as your name, address, and educational institution.
        - Cookies: Cookies track web traffic and user behavior based on implied consent.
        - Google Analytics: We collect data to measure user interactions.
        - Zoho: Used to anonymously track website usage and engagement with emails.
      </p>

      <h2>Why do we collect data?</h2>
      <p>
        We collect data for personalized service, platform security, performance improvement, statistical analysis, marketing, research, and fraud detection.
      </p>

      <h2>How do we secure your data?</h2>
      <p>
        We maintain technical and organizational measures to protect your data against unauthorized access, accidental loss, and unlawful processing. In case of a data breach, we will promptly inform the relevant authorities and affected users.
      </p>

      <h2>What are your rights?</h2>
      <p>
        You have the right to access, rectify, or remove your data. You can unsubscribe from our mailing list or contact us at info@itaccurate.com to request your personal information or ask questions regarding our privacy policy.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
