import React from 'react';
import courseData from '../courseData'; // Import the data
import '../css/Learn.css'; // Include CSS
import { useModal } from "../context/ModelContext";
import DialogContact from "./DialogContact";

const Learn = ({ course }) => {
  // Fetch the course data dynamically based on the prop
  const { openModal } = useModal();

  const handleEnrollClick = () => {
    openModal(<DialogContact />);
  };
  const courseInfo = courseData[course];

  if (!courseInfo) {
    return <h2>Course not found!</h2>;
  }

  return (
    <div className="learn-container">
      <h1>{courseInfo.title}</h1>
      <ul className="learn-points">
        {courseInfo.points.map((point, index) => (
          <li key={index}>{point}</li>
        ))}
      </ul>

      <div className="learn-labels">
        <div className="label">
          <i className="icon-classroom"></i>
          <p>Classroom Training</p>
        </div>
        <div className="label">
          <i className="icon-one-to-one"></i>
          <p>One-on-One Training</p>
        </div>
        <div className="label">
          <i className="icon-online"></i>
          <p>Online Training</p>
        </div>
        <div className="label">
          <i className="icon-customized"></i>
          <p>Customized Training</p>
        </div>
      </div>

      <div className="learn-buttons">
        <button className="demo-button" onClick={handleEnrollClick}>Free Demo Class</button>
      </div>
    </div>
  );
};

export default Learn;
