import React from "react";
import "../css/SubscribeDemo.css";
import DialogContact from "../components/DialogContact"; // Import your DialogContact component
import { useModal } from "../context/ModelContext";
const SubscribeDemo = () => {

  const { openModal } = useModal();
  // Ensure openModal is a valid function
  const handleEnrollClick = () => {
    openModal(<DialogContact />)
  };

  return (
    <div className="subscribe-demo-card">
      <div className="subscribe-card-content">
        {/* Title Section */}
        <div className="subscribe-text">
          <h2 className="text-gray-900 font-semibold">Register for a free demo and kickstart your journey&nbsp;
            <span className="relative ">
            toward placement
              <span
                className="absolute left-0 bottom-0 h-[3px] w-full bg-blue-400"
                style={{ transform: "translateY(4px)" }}
              />
            </span>

          </h2>
          <p>
          Enrolling in a free demo is an important initial step toward landing a job placement. It provides practical exposure to the course content, helping you grasp essential concepts and industry tools. Throughout the demo, you'll be able to engage with skilled trainers, ask questions, and assess the teaching approach, ensuring the training meets your expectations.
          </p>
          <p>
          Moreover, the demo allows you to evaluate your current skill set and pinpoint areas for growth, providing a clearer roadmap toward job readiness. Ultimately, it enhances your confidence and prepares you for success in securing a placement.
          </p>
        </div>

        {/* Benefits Section */}
        <div className="benefits-grid">
          <div className="benefit-card">
            <i className="fas fa-tools"></i>
            <p>Practical Experience</p>
          </div>
          <div className="benefit-card">
            <i className="fas fa-chalkboard-teacher"></i>
            <p>Mentor Interaction</p>
          </div>
          <div className="benefit-card">
            <i className="fas fa-tasks"></i>
            <p>Aptitude Assessment</p>
          </div>
          <div className="benefit-card">
            <i className="fas fa-rocket"></i>
            <p>Confidence Enhancement</p>
          </div>
        </div>

        {/* Button Section */}
        <div className="subscribe-img-section">
          <button className="subscribe-button" onClick={handleEnrollClick}>
           Register
          </button>
        </div>
      </div>
    </div>
  );
};

export default SubscribeDemo;
