import React from 'react';

const Certifications = () => {
  const certificates = [
    {
      src: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSPe027PUV3icGjde9eywSSD-AmApkG2T4vjw&s',
      alt: 'Certificate 1',
      scale: 'scale-120', // Add scale class for pre-zoomed images
    },
    {
      src: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQZhdARmwtnDjhlgcesmZAC1Tb6K0Ga5XVuLQ&s',
      alt: 'Certificate 2',
      scale: 'scale-110',
    },
    {
      src: 'https://upload.wikimedia.org/wikipedia/commons/thumb/0/0f/ISO_9001-2015.svg/1200px-ISO_9001-2015.svg.png',
      alt: 'Certificate 3',
      scale: '',
    },
    {
      src: 'https://soft.nbnfindia.org/Upload/0y8c6mrcMy%20project-1%20(8).png',
      alt: 'Certificate 4',
      scale: '', // Normal size, no scaling
    },
    {
      src: 'https://5.imimg.com/data5/RN/RN/CP/SELLER-4751185/iso-29990-2010-qms.jpg',
      alt: 'Certificate 5',
      scale: '',
    },
    {
      src: 'https://godwins.in/img/SKILLINDIAlogoVERTICAL.png',
      alt: 'Certificate 6',
      scale: 'scale-118',
    },
  ];

  return (
    <div className="py-10 bg-gray-100">
      <h2 className="text-4xl font-bold text-center text-gray-800 mb-8">
        Our Certifications
      </h2>
      <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-6 px-6">
        {certificates.map((certificate, index) => (
          <div key={index} className="bg-white p-4 rounded-lg shadow-lg">
            <img
              src={certificate.src}
              alt={certificate.alt}
              className={`w-full h-48 object-contain ${certificate.scale}`}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Certifications;
