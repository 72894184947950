const coursesData = {
  Salesforce: {
    title: "Salesforce",
    description:
      "Over 35% of developers choose Salesforce, making it the most preferred and sought-after platform in the tech industry. Salesforce continues to dominate as the leading and in-demand technology in the technical world.",
    roles: [
      "Salesforce Administrator",
      "Salesforce Developer",
      "Salesforce Consultant",
      "Salesforce Architect",
      "Salesforce Business Analyst",
      "Salesforce Project Manager",
      "Salesforce Solution Engineer",
    ],
    upcomingRoles: [
      "Integration Specialist",
      "Lightning Developer",
      "Marketing Cloud Specialist",
      "CPQ Specialist",
      "Einstein Analytics Developer",
    ],
    salaries: [
      { role: "Salesforce Administrator", range: "₹3 LPA – ₹5 LPA" },
      { role: "Salesforce Developer", range: "₹4 LPA – ₹6 LPA" },
      { role: "Salesforce Business Analyst", range: "₹4.5 LPA – ₹7 LPA" },
      { role: "Salesforce Consultant", range: "₹5 LPA – ₹8 LPA" },
      { role: "Salesforce Lightning Developer", range: "₹5.5 LPA – ₹9 LPA" },
      { role: "Salesforce Integration Specialist", range: "₹5.5 LPA – ₹9 LPA" },
      { role: "Salesforce Solution Architect", range: "₹7 LPA – ₹10 LPA" },
    ],
  },
  SAP: {
    title: "SAP",
    description:
      "SAP is a world leader in ERP solutions, offering cutting-edge tools that empower businesses to streamline and manage their operations efficiently.",
    roles: [
      "SAP Consultant",
      "SAP Developer",
      "SAP Functional Consultant",
      "SAP Business Analyst",
      "SAP Basis Administrator",
      "SAP Project Manager",
    ],
    upcomingRoles: [
      "SAP Integration Specialist",
      "SAP Cloud Specialist",
      "SAP HANA Developer",
      "SAP FICO Consultant",
      "SAP Security Specialist",
    ],
    salaries: [
      { role: "SAP Consultant", range: "₹5 LPA – ₹10 LPA" },
      { role: "SAP Developer", range: "₹4.5 LPA – ₹9 LPA" },
      { role: "SAP Business Analyst", range: "₹6 LPA – ₹12 LPA" },
      { role: "SAP Basis Administrator", range: "₹5 LPA – ₹8 LPA" },
      { role: "SAP Project Manager", range: "₹8 LPA – ₹15 LPA" },
    ],
  },
  SAP_MM: {
    title: "SAP MM (Materials Management)",
    description:
      "SAP MM focuses on inventory and procurement management, ensuring efficient material flow in organizations.",
    roles: [
      "SAP MM Consultant",
      "Procurement Specialist",
      "Inventory Manager",
      "SAP MM Functional Consultant",
    ],
    upcomingRoles: [
      "Supply Chain Analyst",
      "SAP MM Integration Expert",
      "Automation Specialist for Procurement",
    ],
    salaries: [
      { role: "SAP MM Consultant", range: "₹6 LPA – ₹10 LPA" },
      { role: "Procurement Specialist", range: "₹5 LPA – ₹9 LPA" },
      { role: "Inventory Manager", range: "₹4.5 LPA – ₹8 LPA" },
    ],
  },
  SAP_PM: {
    title: "SAP PM (Plant Maintenance)",
    description:
      "SAP PM ensures smooth maintenance operations for equipment and machinery within industries.",
    roles: [
      "SAP PM Consultant",
      "Maintenance Engineer",
      "SAP Functional Analyst",
    ],
    upcomingRoles: [
      "Equipment Maintenance Specialist",
      "SAP PM Integration Specialist",
    ],
    salaries: [
      { role: "SAP PM Consultant", range: "₹6 LPA – ₹10 LPA" },
      { role: "Maintenance Engineer", range: "₹5 LPA – ₹9 LPA" },
    ],
  },
  SAP_ABAP: {
    title: "SAP ABAP (Advanced Business Application Programming)",
    description:
      "SAP ABAP focuses on developing custom reports and applications using SAP's programming language.",
    roles: ["ABAP Developer", "Technical Consultant", "SAP Programmer"],
    upcomingRoles: ["Advanced ABAP Specialist", "ABAP Workflow Developer"],
    salaries: [
      { role: "ABAP Developer", range: "₹5.5 LPA – ₹9.5 LPA" },
      { role: "Technical Consultant", range: "₹6 LPA – ₹12 LPA" },
    ],
  },
  SAP_QM: {
    title: "SAP QM (Quality Management)",
    description:
      "SAP QM ensures product quality and compliance by managing inspections and audits.",
    roles: [
      "Quality Management Consultant",
      "Quality Analyst",
      "SAP QM Specialist",
    ],
    upcomingRoles: [
      "Inspection Process Specialist",
      "Audit Compliance Analyst",
    ],
    salaries: [
      { role: "Quality Management Consultant", range: "₹5 LPA – ₹10 LPA" },
      { role: "Quality Analyst", range: "₹4 LPA – ₹8 LPA" },
    ],
  },
  SAP_PP: {
    title: "SAP PP (Production Planning)",
    description:
      "SAP PP handles production planning and management for efficient resource utilization.",
    roles: ["SAP PP Consultant", "Production Manager", "Manufacturing Analyst"],
    upcomingRoles: ["Digital Manufacturing Specialist", "PP Automation Expert"],
    salaries: [
      { role: "SAP PP Consultant", range: "₹6 LPA – ₹10 LPA" },
      { role: "Production Manager", range: "₹5.5 LPA – ₹9.5 LPA" },
    ],
  },
  SAP_WMEWM: {
    title:
      "SAP WM & EWM (Warehouse Management & Extended Warehouse Management)",
    description:
      "SAP WM & EWM optimize warehouse operations and logistics processes.",
    roles: ["Warehouse Manager", "SAP EWM Consultant", "Logistics Coordinator"],
    upcomingRoles: [
      "SAP EWM Integration Specialist",
      "Warehouse Automation Expert",
    ],
    salaries: [
      { role: "Warehouse Manager", range: "₹5.5 LPA – ₹9 LPA" },
      { role: "SAP EWM Consultant", range: "₹6.5 LPA – ₹11 LPA" },
    ],
  },
  SAP_LESL: {
    title: "SAP LE & SL (Logistics Execution & Service Logistics)",
    description:
      "SAP LE & SL handle the execution of logistics processes and services.",
    roles: [
      "Logistics Consultant",
      "Service Logistics Coordinator",
      "SAP LE Specialist",
    ],
    upcomingRoles: ["Digital Logistics Expert", "SAP Logistics Integrator"],
    salaries: [
      { role: "Logistics Consultant", range: "₹6 LPA – ₹11 LPA" },
      { role: "Service Logistics Coordinator", range: "₹5 LPA – ₹9 LPA" },
    ],
  },
  SAP_BASIS: {
    title: "SAP BASIS",
    description:
      "SAP BASIS is the technical foundation for SAP systems, ensuring system health and security.",
    roles: [
      "SAP BASIS Administrator",
      "Technical Consultant",
      "System Engineer",
    ],
    upcomingRoles: ["Cloud BASIS Specialist", "System Optimization Expert"],
    salaries: [
      { role: "SAP BASIS Administrator", range: "₹5 LPA – ₹9.5 LPA" },
      { role: "Technical Consultant", range: "₹6 LPA – ₹12 LPA" },
    ],
  },
  SAP_FICO: {
    title: "SAP FICO (Finance & Controlling)",
    description:
      "SAP FICO manages financial transactions and controlling processes for businesses.",
    roles: ["SAP FICO Consultant", "Financial Analyst", "Cost Controller"],
    upcomingRoles: ["Advanced Finance Consultant", "SAP Treasury Specialist"],
    salaries: [
      { role: "SAP FICO Consultant", range: "₹7 LPA – ₹12 LPA" },
      { role: "Financial Analyst", range: "₹5.5 LPA – ₹10 LPA" },
    ],
  },
  SAP_SCM: {
    title: "SAP SCM (Supply Chain Management)",
    description:
      "SAP SCM optimizes supply chain processes for efficient material and information flow.",
    roles: ["SCM Consultant", "Supply Chain Analyst", "Operations Manager"],
    upcomingRoles: [
      "Digital Supply Chain Specialist",
      "Advanced SCM Integrator",
    ],
    salaries: [
      { role: "SCM Consultant", range: "₹7 LPA – ₹13 LPA" },
      { role: "Supply Chain Analyst", range: "₹5.5 LPA – ₹10 LPA" },
    ],
  },
  SAP_HCM: {
    title: "SAP HCM (Human Capital Management)",
    description:
      "SAP HCM focuses on human resource management, including payroll and personnel development.",
    roles: ["HCM Consultant", "HR Manager", "SAP HR Analyst"],
    upcomingRoles: [
      "Workforce Management Specialist",
      "SAP HR System Optimizer",
    ],
    salaries: [
      { role: "HCM Consultant", range: "₹6.5 LPA – ₹11 LPA" },
      { role: "HR Manager", range: "₹5 LPA – ₹9.5 LPA" },
    ],
  },
  SAP_ARIBA: {
    title: "SAP ARIBA",
    description:
      "SAP ARIBA simplifies procurement and supplier collaboration processes.",
    roles: [
      "Procurement Consultant",
      "Supplier Relationship Manager",
      "SAP ARIBA Specialist",
    ],
    upcomingRoles: [
      "Digital Procurement Expert",
      "Supplier Integration Specialist",
    ],
    salaries: [
      { role: "Procurement Consultant", range: "₹5.5 LPA – ₹9.5 LPA" },
      { role: "SAP ARIBA Specialist", range: "₹6 LPA – ₹11 LPA" },
    ],
  },
  SAP_SD: {
    title: "SAP SD (Sales and Distribution)",
    description:
      "SAP SD manages sales processes, including orders, pricing, and customer data.",
    roles: ["SAP SD Consultant", "Sales Analyst", "SAP Functional Analyst"],
    upcomingRoles: ["Advanced Sales Consultant", "CRM Integration Specialist"],
    salaries: [
      { role: "SAP SD Consultant", range: "₹6 LPA – ₹11 LPA" },
      { role: "Sales Analyst", range: "₹5 LPA – ₹9 LPA" },
    ],
  },
  SAP_FIORI: {
    title: "SAP FIORI",
    description:
      "SAP FIORI focuses on user experience (UX) for SAP systems with a modernized UI design.",
    roles: ["SAP FIORI Developer", "UX Designer", "Technical Consultant"],
    upcomingRoles: ["FIORI Workflow Specialist", "SAP UI/UX Expert"],
    salaries: [
      { role: "SAP FIORI Developer", range: "₹6 LPA – ₹12 LPA" },
      { role: "UX Designer", range: "₹5 LPA – ₹9.5 LPA" },
    ],
  },
  DataScience: {
    title: "Data Science",
    description:
      "Data Science stands as one of the most sought-after fields today, blending programming, analytics, and machine learning skills to drive innovation and insights.",
    roles: [
      "Data Scientist",
      "Machine Learning Engineer",
      "Data Engineer",
      "AI Researcher",
      "Business Intelligence Analyst",
    ],
    upcomingRoles: [
      "Deep Learning Specialist",
      "Natural Language Processing Engineer",
      "Big Data Analyst",
      "Data Visualization Expert",
      "AI Product Manager",
    ],
    salaries: [
      { role: "Data Scientist", range: "₹6 LPA – ₹15 LPA" },
      { role: "Machine Learning Engineer", range: "₹7 LPA – ₹16 LPA" },
      { role: "Data Engineer", range: "₹5 LPA – ₹12 LPA" },
      { role: "AI Researcher", range: "₹8 LPA – ₹18 LPA" },
      { role: "Business Intelligence Analyst", range: "₹4.5 LPA – ₹10 LPA" },
    ],
  },
  DataAnalytics: {
    title: "Data Analytics",
    description:
      "Data Analytics enables organizations to harness the power of data, transforming complex datasets into actionable insights for informed decision-making.",
    roles: [
      "Data Analyst",
      "Business Analyst",
      "Market Research Analyst",
      "Operations Analyst",
      "Database Analyst",
    ],
    upcomingRoles: [
      "Predictive Analytics Specialist",
      "Data Quality Analyst",
      "Analytics Consultant",
      "BI Tool Specialist",
      "Data Governance Expert",
    ],
    salaries: [
      { role: "Data Analyst", range: "₹4 LPA – ₹9 LPA" },
      { role: "Business Analyst", range: "₹5 LPA – ₹11 LPA" },
      { role: "Market Research Analyst", range: "₹3 LPA – ₹7 LPA" },
      { role: "Operations Analyst", range: "₹4 LPA – ₹8 LPA" },
      { role: "Database Analyst", range: "₹5 LPA – ₹10 LPA" },
    ],
  },
  BusinessAnalyst: {
    title: "Business Analyst",
    description:
      "Business Analysts connect business objectives with technical solutions, playing a vital role in driving organizational success.",
    roles: [
      "Business Analyst",
      "Functional Analyst",
      "Process Analyst",
      "IT Business Analyst",
      "Project Analyst",
    ],
    upcomingRoles: [
      "Agile Business Analyst",
      "Strategic Analyst",
      "Data-Driven Analyst",
      "Product Analyst",
      "Digital Transformation Analyst",
    ],
    salaries: [
      { role: "Business Analyst", range: "₹5 LPA – ₹10 LPA" },
      { role: "IT Business Analyst", range: "₹6 LPA – ₹12 LPA" },
      { role: "Process Analyst", range: "₹4 LPA – ₹9 LPA" },
      { role: "Project Analyst", range: "₹4.5 LPA – ₹10 LPA" },
      { role: "Strategic Analyst", range: "₹7 LPA – ₹14 LPA" },
    ],
  },
  Python: {
    title: "Python",
    description:
      "Python is a highly versatile programming language, extensively utilized in web development, data science, and automation tasks.",
    roles: [
      "Python Developer",
      "Data Analyst",
      "Machine Learning Engineer",
      "Automation Specialist",
      "Backend Developer",
    ],
    upcomingRoles: [
      "AI Engineer",
      "Cloud Automation Specialist",
      "Python Web Developer",
      "IoT Specialist",
      "Data Engineer",
    ],
    salaries: [
      { role: "Python Developer", range: "₹4 LPA – ₹8 LPA" },
      { role: "Data Analyst", range: "₹4.5 LPA – ₹9 LPA" },
      { role: "Machine Learning Engineer", range: "₹6 LPA – ₹14 LPA" },
      { role: "Automation Specialist", range: "₹5 LPA – ₹11 LPA" },
      { role: "Backend Developer", range: "₹5 LPA – ₹10 LPA" },
    ],
  },
  FullStackDevelopment: {
    title: "Full Stack Development",
    description:
      "Full Stack Development encompasses building and managing both the frontend and backend aspects of web applications.",
    roles: [
      "Full Stack Developer",
      "Frontend Developer",
      "Backend Developer",
      "UI/UX Developer",
      "DevOps Engineer",
    ],
    upcomingRoles: [
      "MERN Stack Developer",
      "MEAN Stack Developer",
      "API Developer",
      "Cloud Application Developer",
      "Serverless Developer",
    ],
    salaries: [
      { role: "Full Stack Developer", range: "₹5 LPA – ₹12 LPA" },
      { role: "Frontend Developer", range: "₹4.5 LPA – ₹10 LPA" },
      { role: "Backend Developer", range: "₹5 LPA – ₹11 LPA" },
      { role: "UI/UX Developer", range: "₹4 LPA – ₹9 LPA" },
      { role: "DevOps Engineer", range: "₹6 LPA – ₹15 LPA" },
    ],
  },
  AWS: {
    title: "AWS",
    description:
      "Amazon Web Services (AWS) is the top cloud platform, providing an extensive array of services to businesses globally.",
    roles: [
      "AWS Solutions Architect",
      "Cloud Engineer",
      "DevOps Engineer",
      "AWS Security Specialist",
      "AWS Developer",
    ],
    upcomingRoles: [
      "Cloud Automation Engineer",
      "Serverless Application Developer",
      "AWS Networking Specialist",
      "Big Data Engineer",
      "Cloud Migration Consultant",
    ],
    salaries: [
      { role: "AWS Solutions Architect", range: "₹7 LPA – ₹15 LPA" },
      { role: "Cloud Engineer", range: "₹5.5 LPA – ₹12 LPA" },
      { role: "DevOps Engineer", range: "₹6 LPA – ₹14 LPA" },
      { role: "AWS Security Specialist", range: "₹8 LPA – ₹16 LPA" },
      { role: "AWS Developer", range: "₹5 LPA – ₹10 LPA" },
    ],
  },
  SalesforceAdmin: {
    title: "Salesforce Admin",
    description:
      "Salesforce Admins are responsible for configuring, customizing, and managing Salesforce platforms to meet organizational needs.",
    roles: [
      "Salesforce Administrator",
      "Salesforce Consultant",
      "System Admin",
    ],
    upcomingRoles: ["Specialized Salesforce Admin", "System Optimizer"],
    salaries: [
      { role: "Salesforce Administrator", range: "₹3.5 LPA – ₹5.5 LPA" },
      { role: "Salesforce Consultant", range: "₹5 LPA – ₹8 LPA" },
    ],
  },
  SalesforceLWC: {
    title: "Salesforce LWC & Integration",
    description:
      "Master the skills to create Lightning Web Components (LWC) and integrate Salesforce with external systems seamlessly.",
    roles: [
      "Lightning Developer",
      "Integration Specialist",
      "Salesforce Developer",
    ],
    upcomingRoles: ["Advanced Integration Expert", "UI Specialist"],
    salaries: [
      { role: "Lightning Developer", range: "₹5.5 LPA – ₹9 LPA" },
      { role: "Integration Specialist", range: "₹5.5 LPA – ₹9 LPA" },
    ],
  },
  SalesforceMarketing: {
    title: "Salesforce Marketing and Cloud",
    description:
      "Salesforce Marketing Cloud specialists lead marketing automation efforts and enhance customer engagement strategies.",
    roles: ["Marketing Cloud Specialist", "Campaign Manager", "CRM Specialist"],
    upcomingRoles: ["Marketing Strategist", "Customer Journey Expert"],
    salaries: [
      { role: "Marketing Cloud Specialist", range: "₹6 LPA – ₹12 LPA" },
      { role: "Campaign Manager", range: "₹5 LPA – ₹10 LPA" },
    ],
  },
  AIML: {
    title: "Artificial Intelligence & Machine Learning (AI & ML)",
    description:
      "AI and Machine Learning are revolutionizing industries by empowering systems to learn, adapt, and make informed decisions. These technologies are among the most sought-after skills worldwide.",
    roles: [
      "AI Engineer",
      "Machine Learning Engineer",
      "Data Scientist",
      "AI Research Scientist",
      "Natural Language Processing Engineer",
      "Computer Vision Specialist",
      "Deep Learning Engineer",
    ],
    upcomingRoles: [
      "AI Ethics Specialist",
      "Edge AI Developer",
      "Autonomous Systems Engineer",
      "AI Product Manager",
      "AI-Powered Robotics Engineer",
    ],
    salaries: [
      { role: "AI Engineer", range: "₹8 LPA – ₹15 LPA" },
      { role: "Machine Learning Engineer", range: "₹7 LPA – ₹14 LPA" },
      { role: "Data Scientist", range: "₹6 LPA – ₹12 LPA" },
      { role: "AI Research Scientist", range: "₹9 LPA – ₹18 LPA" },
      { role: "NLP Engineer", range: "₹7 LPA – ₹14 LPA" },
      { role: "Computer Vision Specialist", range: "₹8 LPA – ₹16 LPA" },
      { role: "Deep Learning Engineer", range: "₹8 LPA – ₹17 LPA" },
    ],
  },
  DevOps: {
    title: "DevOps",
    description:
      "DevOps is a methodology that focuses on fostering collaboration between development and IT teams to automate workflows and accelerate the delivery of high-quality software.",
    roles: [
      "DevOps Engineer",
      "Site Reliability Engineer (SRE)",
      "Build and Release Engineer",
      "Cloud DevOps Engineer",
      "Infrastructure Automation Engineer",
      "CI/CD Pipeline Specialist",
      "Kubernetes Administrator",
    ],
    upcomingRoles: [
      "Platform Engineer",
      "GitOps Specialist",
      "DevSecOps Engineer",
      "Cloud-Native DevOps Engineer",
      "AI-Powered DevOps Specialist",
    ],
    salaries: [
      { role: "DevOps Engineer", range: "₹6 LPA – ₹12 LPA" },
      { role: "Site Reliability Engineer (SRE)", range: "₹7 LPA – ₹15 LPA" },
      { role: "Build and Release Engineer", range: "₹5 LPA – ₹10 LPA" },
      { role: "Cloud DevOps Engineer", range: "₹8 LPA – ₹16 LPA" },
      { role: "Infrastructure Automation Engineer", range: "₹6.5 LPA – ₹13 LPA" },
      { role: "CI/CD Pipeline Specialist", range: "₹6 LPA – ₹11 LPA" },
      { role: "Kubernetes Administrator", range: "₹7 LPA – ₹14 LPA" },
    ],
  },
  ML: {
    title: "Machine Learning (ML)",
    description:
      "Machine Learning allows systems to learn and evolve from experience without explicit programming, driving progress in AI and enhancing data-driven decision-making.",
    roles: [
      "Machine Learning Engineer",
      "Data Scientist",
      "ML Research Scientist",
      "Deep Learning Engineer",
      "Natural Language Processing (NLP) Specialist",
      "Recommendation Systems Engineer",
      "Data Engineer for ML",
    ],
    upcomingRoles: [
      "Edge ML Engineer",
      "AI Algorithm Specialist",
      "Federated Learning Engineer",
      "Explainable AI Specialist",
      "Applied ML Researcher",
    ],
    salaries: [
      { role: "Machine Learning Engineer", range: "₹7 LPA – ₹14 LPA" },
      { role: "Data Scientist", range: "₹6 LPA – ₹12 LPA" },
      { role: "ML Research Scientist", range: "₹8 LPA – ₹16 LPA" },
      { role: "Deep Learning Engineer", range: "₹8 LPA – ₹17 LPA" },
      { role: "NLP Specialist", range: "₹7 LPA – ₹14 LPA" },
      { role: "Recommendation Systems Engineer", range: "₹7 LPA – ₹13 LPA" },
      { role: "Data Engineer for ML", range: "₹6 LPA – ₹12 LPA" },
    ],
  },
};

export default coursesData;
 