import React from "react";
import "../css/WhatsDevOps.css"; // External CSS for the styles

const DevOpsComponent = ({ whatsData }) => {
  return (
    <div id="whats-devops" className="devops-wrapper">
      {whatsData.map((item, index) => (
        <div className="devops-content" key={index}>
          <h1 className="devops-heading">{item.title}</h1>
          <ul className="devops-points">
            {item.points.map((point, idx) => (
              <li key={idx}>{point}</li>
            ))}
          </ul>
        </div>
      ))}
      <div className="devops-circle devops-circle-top-right"></div>
      <div className="devops-circle devops-circle-bottom-left"></div>
    </div>
  );
};

export default DevOpsComponent;
