// RefundPolicy.js
import React from 'react';

const RefundPolicy = () => {
  return (
    <div style={{ padding: '20px', lineHeight: '1.6' , marginTop:'4rem' }}>
      <h1>Refund and Course Rescheduling Policy</h1> {/* Heading added */}
      
      <p>
        Thank you for choosing and purchasing IT Accurate Training courses. We strive to ensure a rewarding experience as you engage with and purchase our training courses, whether for online or classroom learning. As with any online purchase experience, the following are our terms and conditions governing the Refund Policy. When you purchase an online or offline training course from IT Accurate Training, you agree to our Privacy Policy, Terms of Use, and the points below.
      </p>

      <h2>Cancellation and Refunds: Online and Classroom Learning</h2>
      <p>
        IT Accurate Training reserves the right to postpone or cancel events due to instructor illness or force majeure events (such as floods, earthquakes, political instability, pandemics like COVID-19, etc.).
      </p>
      <ul>
        <li>No refund policy applies to online or classroom training. If you mistakenly enroll in another course or wish to switch, it will be based on availability. For changes, reach us at <a href="info@itaccurate.com">info@itaccurate.com</a>.</li>
        <li>No refund is available for discounted courses.</li>
        <li>No refund is available for courses under academic institute partnerships.</li>
        <li>All enrollments in global certification programs are final, and no refund applies.</li>
        <li>If a delegate (student) does not attend on the scheduled date, no refund will be provided.</li>
        <li>Taxes will not be refunded in case of any refund.</li>
      </ul>
      <p>
        <strong>Note:</strong> All refunds will be processed within 30 working days, following review and approval of the request.
      </p>

      <h2>Non-liability for Customer Claims</h2>
      <p>
        IT Accurate Training will not be held liable for any payment debited from a customer’s bank account under the following conditions, and no liability will arise for IT Accurate Training regarding such claims:
      </p>
      <ul>
        <li>The transaction is disputed as unauthorized by the customer.</li>
        <li>The charge/debit on the customer's bank account results from hacking, phishing, or breaches of data security outside IT Accurate's platform.</li>
        <li>Customer claims a refund on any ground, including dissatisfaction with the sale of goods or services provided.</li>
      </ul>
    </div>
  );
};

export default RefundPolicy;
