// import React, { useRef } from "react";
import "../../css/DevOps.css";
import certificateImg from '../../assets/Certificates/IT accurate certificate.png';
// import IMG from '../../assets/logo.svg'
import Roadmap from "../../components/Roadmap";
import WhatsDevOps from "../../components/WhatsDevOps";
// import PageForm from '../../components/PageForm';
import Syllabus from "../../components/Syllabus";
import KeyFeatures from "../../components/KeyFeatures";
import SalaryHikeSection from '../../components/SalaryHike';
import BatchesPage from "../../components/Batches";
import Faq from "../../components/Faq";
import "slick-carousel/slick/slick.css"; // Importing slick carousel styles
import "slick-carousel/slick/slick-theme.css"; // Importing slick theme styles
import Marq from '../../components/CustomMarquee';
import StickyNavbar from "../../components/StickyNavbar";
import JobPreparation from '../../components/JobPreparation';
// import dev1 from '../../assets/Projects/Devops/DEV1.png';
// import dev2 from '../../assets/Projects/Devops/DEV2.png';
// import dev3 from '../../assets/Projects/Devops/DEV3.png';
// import dev4 from '../../assets/Projects/Devops/DEV4.png';
// import dev5 from '../../assets/Projects/Devops/DEV5.png';
// import dev6 from '../../assets/Projects/Devops/DEV6.png';
// import dev7 from '../../assets/Projects/Devops/DEV7.png';
// import dev8 from '../../assets/Projects/Devops/DEV8.png';
// import rev1 from '../../assets/Reviews/DEVOPS/devrev1.png';
// import rev2 from '../../assets/Reviews/DEVOPS/devrev2.png';
// import rev3 from '../../assets/Reviews/DEVOPS/devrev3.png';
// import rev4 from '../../assets/Reviews/DEVOPS/devrev4.png';
// import rev5 from '../../assets/Reviews/DEVOPS/devrev5.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Learn from '../../components/Learn';
import {
  faFileAlt,
  faChalkboardTeacher,
  faBriefcase,
  faCheckCircle,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
// import SideDropdown from '../../components/SideDropdown';
import MarqueeLogos from "../../components/MarqueeLogos";
import ContactForm from "../../components/ContactForm";
const SAPABAP = () => {
  const roadmapData = [
    {
      title: "Introduction to Tableau",
      points: [
        "What is Tableau?",
        "Understanding Tableau Desktop, Tableau Server, and Tableau Online",
        "Tableau architecture and data engine",
        "Downloading and installing Tableau Public",
        "Tableau certification",
        "Tableau jobs in India",
        "Tableau jobs in Mumbai",
      ],
    },
    {
      title: "Data Preparation and Data Sources",
      points: [
        "Connect to various data sources (Excel, SQL, etc.)",
        "Data extraction and data blending",
        "Data preparation with Tableau Prep",
        "Cleaning, shaping, and joining data",
      ],
    },
    {
      title: "Data Modeling and Calculations",
      points: [
        "Create relationships and joins between data sources",
        "Understand and implement Tableau Calculated Fields",
        "Use LOD (Level of Detail) expressions",
        "Optimize data models for performance",
      ],
    },
    {
      title: "Data Visualization in Tableau",
      points: [
        "Create interactive reports and dashboards",
        "Use various visualizations (bar charts, scatter plots, heatmaps, etc.)",
        "Customize visual elements for better storytelling",
        "Best practices for effective data visualization",
      ],
    },
    {
      title: "Tableau Server and Sharing Reports",
      points: [
        "Publish dashboards to Tableau Server and Tableau Online",
        "Understand Tableau Sites and Projects",
        "Share reports with Tableau Reader and Tableau Public",
        "Implement row-level security (RLS) for data access",
      ],
    },
    {
      title: "Advanced Features in Tableau",
      points: [
        "Use Tableau Extensions and Tableau Bridge",
        "Create custom visuals with R and Python scripts",
        "Automate data refresh schedules",
      ],
    },
    {
      title: "Tableau Integration and Tableau Extensions",
      points: [
        "Integrate Tableau with Salesforce and other platforms",
        "Understand Tableau REST API and Web Data Connectors",
        "Enable real-time data streaming in Tableau dashboards",
      ],
    },
    {
      title: "Resume Preparation for Tableau Roles",
      points: [
        "Include relevant Tableau skills and projects",
        "Optimize resume with Tableau keywords",
        "Showcase your data visualization and LOD skills",
        "Highlight problem-solving and data analysis abilities",
        "Be unique and concise",
      ],
    },
  ];

  const syllabus = [
    {
      title: "Introduction to Tableau",
      subpoints: [
        "What is Tableau?",
        "History & Evolution of Tableau",
        "Understanding Tableau Desktop, Tableau Server, and Tableau Online",
        "Tableau Architecture and Data Engine",
        "Installing and Setting Up Tableau Desktop",
        "Tableau vs other BI tools",
      ],
    },
    {
      title: "Data Preparation and Data Sources",
      subpoints: [
        "Introduction to Data Sources in Tableau",
        "Connecting to Data Sources (Excel, SQL, Web, etc.)",
        "Using Tableau Prep for Data Transformation",
        "Data Cleansing and Shaping in Tableau",
        "Data Types and Formatting in Tableau",
        "Live Connection vs Extract Mode",
        "Understanding Data Blending and Data Joins",
      ],
    },
    {
      title: "Data Modeling and Calculations",
      subpoints: [
        "Introduction to Data Modeling in Tableau",
        "Creating Relationships Between Data Sources",
        "Star Schema vs Snowflake Schema in Tableau",
        "Introduction to Tableau Calculations",
        "Tableau Calculated Fields: SUM, COUNT, IF, etc.",
        "LOD (Level of Detail) Expressions",
        "Date and Time Calculations in Tableau",
        "Performance Optimization for Calculations",
      ],
    },
    {
      title: "Data Visualization in Tableau",
      subpoints: [
        "Introduction to Visualizations in Tableau",
        "Working with Charts: Bar, Line, Pie, etc.",
        "Creating Tables, Matrices, and Dashboards",
        "Using Maps and Geo-spatial Visuals",
        "Customizing Visuals for Effective Storytelling",
        "Using Filters, Parameters, and Actions",
        "Creating Interactive Dashboards and Story Points",
        "Conditional Formatting and KPI Indicators",
      ],
    },
    {
      title: "Tableau Server and Sharing Reports",
      subpoints: [
        "Introduction to Tableau Server and Tableau Online",
        "Publishing Dashboards to Tableau Server",
        "Creating and Managing Tableau Workbooks and Projects",
        "Collaborating with Teams in Tableau",
        "Sharing Dashboards and Reports with Tableau Reader and Tableau Public",
        "Row-Level Security (RLS) in Tableau",
        "Data Refresh and Scheduling in Tableau",
      ],
    },
    {
      title: "Tableau Advanced Analytics",
      subpoints: [
        "Introduction to Tableau AI and Advanced Analytics",
        "Using Ask Data (Natural Language Queries)",
        "Creating Forecasts and Trend Analysis",
        "Tableau Decomposition Tree and Key Influencers",
        "Integrating Python and R with Tableau for Advanced Analytics",
        "Time Series Analysis in Tableau",
      ],
    },
    {
      title: "Tableau Integration and Tableau Extensions",
      subpoints: [
        "Integrating Tableau with Power BI, Salesforce, and Other Platforms",
        "Automating Workflows with Tableau and Power Automate",
        "Embedding Tableau Dashboards in Web Applications",
        "Introduction to Tableau REST API",
        "Connecting Tableau to SharePoint, Google Analytics, and Dynamics 365",
      ],
    },
    {
      title: "Tableau Performance Optimization",
      subpoints: [
        "Optimizing Tableau Data Models and Workbooks",
        "Improving Query Performance in Tableau",
        "Using Extracts for Large Datasets",
        "Best Practices for Dashboard Design and Performance",
        "Memory and Storage Optimization in Tableau",
      ],
    },
    {
      title: "Tableau Administration",
      subpoints: [
        "Admin Roles in Tableau Server and Tableau Online",
        "Tableau Server and Site Administration",
        "Monitoring and Auditing Tableau Usage",
        "Governance and Compliance in Tableau",
        "Tableau Licensing Models (Creator, Explorer, Viewer)",
      ],
    },
    {
      title: "Resume Preparation for Tableau Careers",
      subpoints: [
        "Highlighting Tableau Skills and Projects on Your Resume",
        "Using Tableau Keywords Effectively",
        "Showcasing Data Visualization and Calculations Skills",
        "Preparing for Tableau Interviews",
        "Building an Impressive Tableau Portfolio",
        "Soft Skills for Data Professionals",
      ],
    },
  ];

  const faqs = [
    {
      question: "What is Tableau?",
      answer:
        "Tableau is a data visualization and business intelligence tool that helps you to analyze, visualize, and share data insights through interactive dashboards and reports.",
    },
    {
      question: "What are the different components of Tableau?",
      answer:
        "Tableau consists of Tableau Desktop, Tableau Server, Tableau Online, Tableau Prep, and Tableau Public.",
    },
    {
      question: "What data sources can Tableau connect to?",
      answer:
        "Tableau can connect to a variety of data sources including Excel, SQL Server, Google Analytics, Salesforce, Amazon Redshift, and many others.",
    },
    {
      question: "What are Calculated Fields in Tableau?",
      answer:
        "Calculated Fields in Tableau are user-defined fields that allow you to perform custom calculations on your data within a report or visualization.",
    },
    {
      question: "How do you create a dashboard in Tableau?",
      answer:
        "To create a dashboard in Tableau, you can drag and drop multiple worksheets onto a dashboard workspace, customize the layout, and add interactivity such as filters and actions.",
    },
    {
      question: "What is Tableau Prep?",
      answer:
        "Tableau Prep is a data preparation tool that helps you clean, shape, and transform data before using it in Tableau Desktop for analysis and visualization.",
    },
    {
      question:
        "What is the difference between Live Connection and Extracts in Tableau?",
      answer:
        "Live Connection retrieves data directly from the source in real-time, while Extracts store a snapshot of the data for faster performance but with less up-to-date information.",
    },
    {
      question: "How can you share a Tableau report?",
      answer:
        "Reports can be shared via Tableau Server or Tableau Online by publishing the workbook, sharing a link, or embedding the dashboard in other applications.",
    },
    {
      question: "What are Parameters in Tableau?",
      answer:
        "Parameters in Tableau are dynamic values that allow users to modify aspects of a visualization or calculation, enabling more interactivity and customization in reports.",
    },
    {
      question: "What is Row-Level Security (RLS) in Tableau?",
      answer:
        "RLS in Tableau allows you to control which data is visible to different users by defining security rules based on user roles and data filters.",
    },
  ];

  const whatsData = [
    {
      title: "What is Tableau?",
      points: [
        "Tableau is a leading data visualization and business intelligence tool that allows users to connect to various data sources, clean and transform data, and create interactive dashboards and reports.",
        "Tableau enables organizations to make data-driven decisions by providing powerful data analysis capabilities and interactive visualizations.",
        "There are several components in Tableau: Tableau Desktop for report creation, Tableau Server for sharing dashboards in an enterprise environment, Tableau Online for cloud-based sharing, and Tableau Prep for data preparation and transformation.",
        "IT Accurate offers comprehensive Tableau training in both offline and online modes, covering everything from data preparation to advanced dashboard creation using calculated fields and Tableau Prep.",
        "Professionals in IT, finance, marketing, and even non-technical backgrounds can start their career in data analytics with Tableau, making it accessible to a wide range of learners.",
        "IT Accurate is the best training institute in Mumbai, offering hands-on training and 100% job assistance for those looking to enhance their career in data analytics with Tableau.",
      ],
    },
  ];

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // const imageList = [
  //     { Image: dev1, alt: "Award 1" },
  //     { Image: dev2, alt: "Award 2" },
  //     { Image: dev3, alt: "Award 3" },
  //     { Image: dev4, alt: "Award 4" },
  //     { Image: dev5, alt: "Award 5" },
  //     { Image: dev6, alt: "Award 6" },
  //     { Image: dev7, alt: "Award 7" },
  //     { Image: dev8, alt: "Award 8" },
  // ];

  // const reviewList = [
  //     { Image: rev1, alt: "rev1" },
  //     { Image: rev2, alt: "rev1" },
  //     { Image: rev3, alt: "rev1" },
  //     { Image: rev4, alt: "rev1" },
  //     { Image: rev5, alt: "rev1" },
  // ]
  const keyFeatures = [
    { text: "Limited Students Batch", icon: "fa-users" },
    { text: "Personalised Attention", icon: "fa-user-check" },
    { text: "Highly Qualified Teachers", icon: "fa-chalkboard-teacher" },
    { text: "Flexible Batch Timings", icon: "fa-calendar-alt" },
    { text: "Interactive Learning", icon: "fa-comments" },
    { text: "Live Projects", icon: "fa-laptop-code" },
    { text: "Career Support", icon: "fa-briefcase" },
    { text: "Job Oriented Training", icon: "fa-graduation-cap" },
  ];
  return (
    <div className="devops-page">
      {/* <SideDropdown roadmapData={modulesData} /> */}
      <div className="content-container">
        <div className="devops-circle devops-circle-bottom-left"></div>
        <div className="left-content">
          <h1>Best Tableau Training</h1>
          <div className="stars-section">
            {[...Array(5)].map((_, index) => (
              <FontAwesomeIcon
                key={index}
                icon={faStar}
                className="star-icon"
              />
            ))}
          </div>
          <div className="ratings-info">
            <span className="rating">(4.7)</span>
            <span className="separator">|</span>
            <span className="ratings-count">3489 Ratings</span>
            <span className="separator">|</span>
            <span className="learners-count">4467 Learners</span>
          </div>
          <p>
            IT Accurate offers the best Tableau training in Mumbai for both
            freshers and experienced professionals, featuring expert guidance
            and a 100% job placement guarantee. Our Tableau course covers all
            essential topics, ensuring students gain real-time, hands-on
            experience with data visualization and business intelligence.
          </p>
          <br />
          <p>
            Our practical, job-oriented training program not only provides a
            certification but also equips you with skills equivalent to 2+ years
            of industry experience. We value your time, which is why our Tableau
            training is designed to be completed in just 3 months, focusing on
            practical and project-based learning.
          </p>

          <ul className="points-list">
            <li>Data Visualization and Dashboard Creation - 1 month</li>
            <li>Tableau Data Preparation and Transformation - 1 month</li>
            <li>Project-based Dashboard Development - 1 month</li>
          </ul>
          <h3>All the topics will be covered in detail and also include:</h3>
          <ul className="points-list">
            <li>
              <FontAwesomeIcon icon={faFileAlt} /> Resume preparation and
              portfolio building
            </li>
            <li>
              <FontAwesomeIcon icon={faChalkboardTeacher} /> Interview
              preparation and mock interviews
            </li>
            <li>
              <FontAwesomeIcon icon={faBriefcase} /> 6-month internship with
              live projects
            </li>
            <li>
              <FontAwesomeIcon icon={faCheckCircle} /> 100% job placement
              assistance
            </li>
          </ul>
        </div>

        <div className="form-contact">
          <ContactForm title="Get in Touch" submitText="Submit" />
        </div>
      </div>
      <StickyNavbar name="Tableau" />
      <WhatsDevOps whatsData={whatsData} />
      <SalaryHikeSection />
      <Learn course="tableau" />
      <Roadmap heading="Roadmap to Learn Tableau" roadmapData={roadmapData} />
      <JobPreparation />
      <KeyFeatures heading=" Tableau  Training" keyFeatures={keyFeatures} />
      <Syllabus heading=" Tableau  Training Syllabus" Syllabus={syllabus} />
     
     
      

      <section id="certification" className="devops-certification-section">
        <h2 className="certification-heading"> Tableau CERTIFICATION</h2>
        <div className="certification-content">
          <div className="certification-points">
            <ul>
              <li>
                IT Accurate training certification will serve as proof that you
                have completed the comprehensive Tableau training program.
              </li>
              <li>
                The Tableau certification provided by IT Accurate equips you
                with in-demand data visualization and analytics skills,
                enhancing your job prospects in data analysis and business
                intelligence.
              </li>
              <li>
                IT Accurate offers detailed guidance for Tableau global
                certifications, ensuring a 100% passing guarantee in exams such
                as Tableau Desktop Specialist, Tableau Certified Associate, and
                other recognized global certifications.
              </li>
            </ul>
          </div>

          <div className="certification-image">
            <img src={certificateImg} alt="DevOps Certification" />
          </div>
        </div>
      </section>

      {/* <Roadmap heading="SAP modules" roadmapData={modulesData} /> */}

      {/* devops project Section
            <div className="slider-container">
                <h2 className="slider-heading">Devops Project</h2>
                <RegularSlider sliderSettings={sliderSettings} images={imageList} />
            </div> */}
           
            <Marq />

      <Faq Faqs={faqs} />

      {/* <div className="slider-container">
                <h2 className="slider-heading">SAP ABAP Training courses review</h2>
                <RegularSlider sliderSettings={sliderSettings} images={reviewList} />
            </div> */}

      <MarqueeLogos />
    </div>
  );
};

export default SAPABAP;
