import React, { useEffect, useRef } from "react";
import "../../css/DevOps.css";
import Alumni from "../../components/MarqueeLogos";
import certificateImg from '../../assets/Certificates/IT accurate certificate.png';
import Roadmap from "../../components/Roadmap";
import WhatsDevOps from "../../components/WhatsDevOps";
import SalaryHikeSection from '../../components/SalaryHike';
import Syllabus from "../../components/Syllabus";
import KeyFeatures from "../../components/KeyFeatures";
import Faq from "../../components/Faq";
import BatchesPage from "../../components/Batches";
import "slick-carousel/slick/slick.css"; // Importing slick carousel styles
import "slick-carousel/slick/slick-theme.css"; // Importing slick theme styles
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import StickyNavbar from "../../components/StickyNavbar";
import {
  faFileAlt,
  faChalkboardTeacher,
  faBriefcase,
  faCheckCircle,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import ContactForm from "../../components/ContactForm";
import Learn from '../../components/Learn';
import Marq from '../../components/CustomMarquee';
import JobPreparation from '../../components/JobPreparation';
import CourseOpportunities from "../../components/CourseOpportunities";
// project slider image
// import dev1 from '../../assets/Projects/Salesforce/1.png';
// import dev2 from '../../assets/Projects/Salesforce/2.png';
// import dev3 from '../../assets/Projects/Salesforce/3.png';
// import dev4 from '../../assets/Projects/Salesforce/4.png';
// import dev5 from '../../assets/Projects/Salesforce/5.png';
// import dev6 from '../../assets/Projects/Salesforce/6.png';
// import dev7 from '../../assets/Projects/Salesforce/7.png';
// import dev8 from '../../assets/Projects/Salesforce/8.png';
// import dev9 from '../../assets/Projects/Salesforce/9.png';
// import dev10 from '../../assets/Projects/Salesforce/10.png';

// import rev1 from '../../assets/Reviews/Salesforce/Sfcloudrev1.png';
// import rev2 from '../../assets/Reviews/Salesforce/Sfcloudrev2.png';
// import rev3 from '../../assets/Reviews/Salesforce/Sfcloudrev3.png';
// import rev4 from '../../assets/Reviews/Salesforce/Sfcloudrev4.png';
// import rev5 from '../../assets/Reviews/Salesforce/Sfcloudrev5.png';
// import rev6 from '../../assets/Reviews/Salesforce/Sfcloudrev6.png';
// import rev7 from '../../assets/Reviews/Salesforce/Sfcloudrev7.png';
// import rev8 from '../../assets/Reviews/Salesforce/Sfcloudrev8.png';
// import RegularSlider from '../../Components/RegularSlider';

const SalesforceMarketing = () => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);

  useEffect(() => {
    // Function to create the YouTube player
    const createPlayer = () => {
      if (window.YT && window.YT.Player) {
        playerRef.current = new window.YT.Player(videoRef.current, {
          videoId: "syzh1IxEaak", // Updated YouTube video ID
          events: {
            onReady: (event) => {
              // event.target.mute(); // Mute the video to allow autoplay
              event.target.playVideo();
            },
          },
          playerVars: {
            loop: 1,
            controls: 0, // Hide video controls
            modestbranding: 1, // Minimal YouTube branding
            playlist: "syzh1IxEaak", // Required for looping
          },
        });
      }
    };

    // Load YouTube IFrame API if it's not already loaded
    if (!window.YT) {
      const tag = document.createElement("script");
      tag.src = "https://www.youtube.com/iframe_api";
      const firstScriptTag = document.getElementsByTagName("script")[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

      // Set up callback for when the API is ready
      window.onYouTubeIframeAPIReady = () => {
        createPlayer();
      };
    } else {
      createPlayer();
    }
  }, []);

  const roadmapData = [
    {
      title: "Introduction",
      points: [
        "What is Salesforce Marketing Cloud ?",
        "Marketing Cloud course",
        "Marketing Cloud certification",
        "Marketing Cloud jobs in india",
      ],
    },

    {
      title: "Understanding Salesforce Ecosystem",

      points: [
        "Learn about Salesforce CRM and its modules.",
        "Understand the role of Marketing Cloud within the Salesforce ecosystem.",
        "Explore how Marketing Cloud integrates with other Salesforce products.",
      ],
    },

    {
      title: "Core Concepts of Marketing Cloud",
      points: [
        "Familiarize yourself with Email Studio, Mobile Studio, and Social Studio.",
        "Learn about Journey Builder for creating customer journeys.",
        "Understand the capabilities of Advertising Studio for digital advertising campaigns.",
      ],
    },

    {
      title: "Email Marketing in Marketing Cloud",
      points: [
        "Learn how to create and manage email campaigns using Email Studio.",
        "Explore best practices for designing engaging email templates.",
        "Understand how to segment audiences and personalize email content.",
      ],
    },

    {
      title: "Social Media Marketing and Mobile Messaging",
      points: [
        "Explore Social Studio for social media management and monitoring.",
        "Learn about Mobile Studio for creating SMS and push notification campaigns.",
        "Understand strategies for integrating social media and mobile messaging into marketing campaigns.",
      ],
    },
    {
      title: "Customer Journey Management",
      points: [
        "Master Journey Builder for designing and automating customer journeys.",
        "Learn how to create personalized experiences based on customer interactions.",
        "Understand the importance of data-driven decision-making in optimizing customer journeys.",
      ],
    },
    {
      title: " Analytics and Optimization",
      points: [
        "Explore Marketing Cloud Analytics for tracking campaign performance.",
        "Learn how to analyze data to measure ROI and optimize marketing strategies.",
        "Understand A/B testing and predictive analytics for continuous improvement.",
      ],
    },
    {
      title: "Resume preparation",
      points: [
        "Include keywords",
        "How to prepare resume",
        "How to prepare resume for freshers.",
        "Show impact",
        "Include soft skills",
        "Be unique",
      ],
    },
  ];

  const syllabus = [
    {
      title: "Introduction To Marketing Cloud",
      // subpoints: [
      //   'Basic Of HTML.',
      //   'HTML Tags',
      //   'HTML Attributes',
      //   'Inline CSS',
      // ],
    },
    {
      title: "Email Studio",
      // subpoints: [
      //   'DataTypes In Java Script',
      //   'Interaction with alert, prompt and confirm',
      //   'Variables In JS',
      //   'Function and Methods In JS',
      //   'Object In JS',
      // ],
    },
    {
      title: "Journey Builder:",
      // subpoints: [
      //   'Base Componenets In LWC',
      //   'Lightning-Card',
      //   'Lightning-Input',
      //   'Lightning-Button',
      //   'Lightning Record Edit Form',
      //   'Lightning View Form',
      //   'Lightning Input Form',
      //   'Lightning Output Form',
      // ],
    },
    {
      title: "Mobile Studio",
      // subpoints: [
      //   'Track',
      //   'API',
      //   'WIRE',
      // ],
    },
    {
      title: "Social Studio",
      // subpoints: [
      //   'Constructors',
      //   'Connected Call Back',
      //   'Disconnected Call Back',
      //   'Rendered Call Back',
      //   'Error Call Back',
      // ],
    },
    {
      title: "Data Management",
      // subpoints: [
      //   'Parent to Child Communication',
      //   'Child to Parent Communication',
      //   'Getter and Setter Methods',
      // ],
    },
    {
      title: "Personalization",
      // subpoints: [
      //   'How to Used lds in LWC',
      //   'LDS Components',
      // ],
    },
    {
      title: "Analytics and Reporting",
      // subpoints: [
      //   'Exeptions',
      //   'Exception Statements',
      //   'System-Defined Exception',
      //   'Catching Different Exception Types',
      //   'Try Catch and Finally Methods',
      // ],
    },
    {
      title: "APEX Testing Essentials",
      subpoints: [
        "Write and run APEX Tests",
        "Create Tests Data",
        "Running Test Units",
        "Testing Best Practices",
        "Code Coverage",
        "Test Classes Methods",
      ],
    },
  ];

  const keyFeatures = [
    { text: "34+ Hrs Instructor-Led Training", icon: "fa-chalkboard-teacher" },
    { text: "Certificate Guidance", icon: "fa-certificate" },
    { text: "Mentor Support", icon: "fa-user-friends" },
    { text: "Resume Editing", icon: "fa-file-alt" },
    { text: "10+ Hrs Project & Exercises", icon: "fa-tasks" },
    { text: "100% Job Oriented Training", icon: "fa-graduation-cap" },
    { text: "56+ Hrs Self-Paced Videos", icon: "fa-video" },
    { text: "Job Assistance", icon: "fa-briefcase" },
    { text: "Free Demo Class Available", icon: "fa-chalkboard" },
    { text: "Affordable Fee Structure", icon: "fa-money-bill-wave" },
    { text: "Flexible Schedule", icon: "fa-calendar-alt" },
    { text: "Completed 420+ Batches", icon: "fa-users" },
  ];

  const faqs = [
    {
      question: "What is Salesforce Marketing Cloud?",
      answer:
        "Describes the integration capabilities between Marketing Cloud and Salesforce CRM for a unified customer experience.",
    },
    {
      question: "How does Marketing Cloud integrate with Salesforce CRM?",
      answer:
        "Describes the integration capabilities between Marketing Cloud and Salesforce CRM for a unified customer experience.",
    },
    {
      question: "What are the key modules of Salesforce Marketing Cloud?",
      answer:
        "Lists and explains the core modules like Email Studio, Social Studio, Mobile Studio, Advertising Studio, and Journey Builder.",
    },
    {
      question: "How can Marketing Cloud help in email marketing?",
      answer:
        "Details the functionalities and benefits of Marketing Cloud’s Email Studio for creating and managing email campaigns.",
    },
    {
      question: "What is Journey Builder in Marketing Cloud?",
      answer:
        "Describes the functionality of Journey Builder for creating personalized customer journeys across multiple channels.",
    },
    {
      question: "How does Marketing Cloud handle social media marketing?",
      answer:
        "Discusses the capabilities of Social Studio for managing social media campaigns and customer interactions.",
    },
    {
      question: "What are the features of Mobile Studio in Marketing Cloud?",
      answer:
        "Explains how Mobile Studio enables businesses to create and manage SMS and push notification campaigns.",
    },
    {
      question: "How does Marketing Cloud Support data-driven marketing?",
      answer:
        "Discusses the importance of data integration and analytics in Marketing Cloud for data-driven marketing strategies.",
    },
    {
      question: "What are the pricing plans for Salesforce Marketing Cloud?",
      answer:
        "Provides an overview of the pricing structure and plans available for using Salesforce marketing cloud services..",
    },
  ];

  const whatsData = [
    {
      title: "What is Salesforce Marketing Cloud?",
      points: [
        "Salesforce Marketing Cloud is a comprehensive digital marketing platform that helps businesses engage with customers across various channels.",
        "It offers tools for email marketing, social media marketing, mobile messaging, advertising, and customer journey management.",
        "Users can create personalized marketing campaigns, track customer interactions, and analyze campaign performance.",
        "Salesforce Marketing Cloud integrates with Salesforce CRM, allowing businesses to combine customer data for more effective marketing strategies.",
        "IT Accurate covers all the essential data analytics skills to help you get hired in 2024.",
        "IT Accurate provides both offline and online Data Analytics training, with real-time project-based learning.",
        "People from both IT and non-IT backgrounds, including freshers and experienced individuals, can start a career in Data Analytics.",
        "IT Accurate is one of the best training institutes in Mumbai, offering 100% job placement opportunities.",
      ],
    },
  ];

  // const sliderSettings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 3,
  //   slidesToScroll: 1,
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 600,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //       },
  //     },
  //   ],
  // };

  // const imageList = [
  //   { Image: dev1, alt: "Award 1" },
  //   { Image: dev2, alt: "Award 2" },
  //   { Image: dev3, alt: "Award 3" },
  //   { Image: dev4, alt: "Award 4" },
  //   { Image: dev5, alt: "Award 5" },
  //   { Image: dev6, alt: "Award 6" },
  //   { Image: dev7, alt: "Award 7" },
  //   { Image: dev8, alt: "Award 8" },
  //   { Image: dev9, alt: "Award 8" },
  //   { Image: dev10, alt: "Award 8" },
  // ];

  // const reviewList = [
  //   { Image: rev1, alt: "rev1" },
  //   { Image: rev2, alt: "rev2" },
  //   { Image: rev3, alt: "rev3" },
  //   { Image: rev4, alt: "rev4" },
  //   { Image: rev5, alt: "rev5" },
  //   { Image: rev6, alt: "rev6" },
  //   { Image: rev7, alt: "rev7" },
  //   { Image: rev8, alt: "rev8" },
  // ]

  return (
    <div className="devops-page">
      <div className="content-container">
        <div className="devops-circle devops-circle-bottom-left"></div>
        <div className="left-content">
          <h1>Best Salesforce Marketing Cloud Training</h1>
          <div className="stars-section">
            {[...Array(5)].map((_, index) => (
              <FontAwesomeIcon
                key={index}
                icon={faStar}
                className="star-icon"
              />
            ))}
          </div>
          <div className="ratings-info">
            <span className="rating">(4.9)</span>
            <span className="separator">|</span>
            <span className="ratings-count">5489 Ratings</span>
            <span className="separator">|</span>
            <span className="learners-count">6367 Learners</span>
          </div>
          <p>
            Best Salesforce Marketing Cloud Training by IT Accurate offers you
            the best Salesforce Marketing Cloud training in Mumbai for freshers
            and experienced candidates, with expert guidance and 100% guaranteed
            placement assistance. Our Salesforce Marketing Cloud training course
            covers every technical topic in detail, and students gain real-time
            hands-on experience.
          </p>
          <br />
          <p>
            Our practical, job-oriented training program will not only provide
            you with a certificate but also with knowledge equivalent to around
            10+ years of field experience. We value your time as much as ours.
            Hence, we aim to train you in Salesforce Marketing Cloud in just 3
            months.
          </p>

          <h3>All topics will be covered in detail and include:</h3>
          <ul className="points-list">
            <li>
              <FontAwesomeIcon icon={faFileAlt} /> ISO Certification
            </li>
            <li>
              <FontAwesomeIcon icon={faFileAlt} /> Industry-recognized
              certification
            </li>
            <li>
              <FontAwesomeIcon icon={faChalkboardTeacher} /> Interview
              preparation
            </li>
            <li>
              <FontAwesomeIcon icon={faCheckCircle} /> Case studies
            </li>
            <li>
              <FontAwesomeIcon icon={faBriefcase} /> 5+ Capstone projects
            </li>
            <li>
              <FontAwesomeIcon icon={faCheckCircle} /> Industry-level projects
            </li>
            <li>
              <FontAwesomeIcon icon={faCheckCircle} /> 100% job opportunities
              guaranteed program
            </li>
          </ul>
        </div>

        {/* Right Side - Contact Form */}
        <div className="form-contact">
          <ContactForm title="Get in Touch" submitText="Submit" />
        </div>
      </div>
      <div className="video-section">
        <div className="video-container" controls ref={videoRef}></div>
      </div>
      
 <StickyNavbar name="SalesforceMarketing" />

      <WhatsDevOps whatsData={whatsData} />
      <SalaryHikeSection />
      <BatchesPage Batches={BatchesPage} />
     <Learn course="salesforceMarketingCloud" />
      <Roadmap
        heading="Roadmap to learn Salesforce Marketing Cloud"
        roadmapData={roadmapData}
      />
        <JobPreparation />
        <KeyFeatures
        heading="Salesforce Marketing Cloud"
        keyFeatures={keyFeatures}
      />
      <Syllabus
        heading="Salesforce Marketing Cloud Syllabus"
        Syllabus={syllabus}
      />

      {/* <div className="batch-schedule-section">
        <h2>Upcoming Batch Schedule for Salesforce Marketing Cloud Training</h2>
        <p>
          Sapalogy provides flexible timings to all our students. Here is the Salesforce Marketing Cloud Training Class Schedule in our branches. If this schedule doesn’t match your availability, please let us know. We will try to arrange appropriate timings based on your flexible timings.
        </p>
        <div className="batch-schedule-table-wrapper">
          <table className="batch-schedule-table">
            <thead>
              <tr>
                <th>Course</th>
                <th>Batch Time</th>
                <th>Offline</th>
                <th>Online</th>
                <th>Enquire Now</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Salesforce Marketing Cloud</td>
                <td>Starts every week</td>
                <td>Nagpur</td>
                <td>India</td>
                <td><a href="/contact">Enquire Now</a></td>
              </tr>
            </tbody>
          </table>
        </div></div> */}

      <section id="certification" className="devops-certification-section">
        <h2 className="certification-heading">
          Salesforce Marketing Cloud Certification
        </h2>
        <div className="certification-content">
          <div className="certification-points">
            <ul>
              <li>
                The IT Accurate training certificate will confirm that you
                completed the courses at IT Accurate.
              </li>
              <li>
                The Salesforce Marketing Cloud certification from IT Accurate
                will provide you with essential skills, boosting your chances in
                the job market.
              </li>
              <li>
                IT Accurate offers full support for your Salesforce Marketing
                Cloud certification, ensuring a 100% pass rate in exams like
                Salesforce Marketing Cloud Certification, Salesforce Marketing
                Cloud Platform Development Certification, and other global
                certifications.
              </li>
            </ul>
          </div>
          <div className="certification-image">
            <img src={certificateImg} alt="DevOps Certification" />
          </div>
        </div>
      </section>
      <CourseOpportunities pageName="SalesforceMarketing" />
    
      <Marq />

      {/* devops project Section */}
      {/* <div className="slider-container">
        <h2 className="slider-heading">Salesforce Marketing Cloud</h2>
        <RegularSlider sliderSettings={sliderSettings} images={imageList} />
      </div> */}
      <Faq Faqs={faqs} />
      {/* <div className="slider-container">
        <h2 className="slider-heading">Training courses review</h2>
        <RegularSlider sliderSettings={sliderSettings} images={reviewList} />
      </div> */}
      <Alumni />
    </div>
  );
};

export default SalesforceMarketing;
