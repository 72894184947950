import React, { useEffect, useRef } from "react";
import "../../css/DevOps.css";
import Alumni from "../../components/MarqueeLogos";
import certificateImg from '../../assets/Certificates/IT accurate certificate.png';
import Roadmap from "../../components/Roadmap";
import WhatsDevOps from "../../components/WhatsDevOps";
import Syllabus from "../../components/Syllabus";
import KeyFeatures from "../../components/KeyFeatures";
import Faq from "../../components/Faq";
import "slick-carousel/slick/slick.css"; // Importing slick carousel styles
import "slick-carousel/slick/slick-theme.css"; // Importing slick theme styles
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import StickyNavbar from "../../components/StickyNavbar";
import {
  faFileAlt,
  faChalkboardTeacher,
  faBriefcase,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import ContactForm from "../../components/ContactForm";
import CourseOpportunities from '../../components/CourseOpportunities'

const SAPSCM = () => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);

  // useEffect(() => {
  //     // Function to create the YouTube player
  //     const createPlayer = () => {
  //         if (window.YT && window.YT.Player) {
  //             playerRef.current = new window.YT.Player(videoRef.current, {
  //                 videoId: 'i3HdVH6gdfE', // Updated YouTube video ID
  //                 events: {
  //                     onReady: (event) => {
  //                         event.target.mute(); // Mute the video to allow autoplay
  //                         // event.target.playVideo();
  //                     },
  //                 },
  //                 playerVars: {
  //                     loop: 1,
  //                     controls: 0, // Hide video controls
  //                     modestbranding: 1, // Minimal YouTube branding
  //                     playlist: 'i3HdVH6gdfE', // Required for looping
  //                 },
  //             });
  //         }
  //     };

  //     // Load YouTube IFrame API if it's not already loaded
  //     if (!window.YT) {
  //         const tag = document.createElement('script');
  //         tag.src = 'https://www.youtube.com/iframe_api';
  //         const firstScriptTag = document.getElementsByTagName('script')[0];
  //         firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

  //         // Set up callback for when the API is ready
  //         window.onYouTubeIframeAPIReady = () => {
  //             createPlayer();
  //         };
  //     } else {
  //         createPlayer();
  //     }
  // }, []);
  const modulesData = [
    {
      title: "SAP",
      points: [
        "IT Accurate Training in Mumbai offers expert SAP training, empowering you to efficiently manage SAP systems and ensure seamless operations.",
      ],
      link: "/best-sap-training",
    },
    {
      title: "SAP BASIS",
      points: [
        "IT Accurate Training in Mumbai offers expert SAP BASIS training, empowering you to efficiently manage SAP systems and ensure seamless operations.",
      ],
      link: "/sap-basis-training-in-mumbai",
    },
    {
      title: "SAP MM",
      points: [
        "SAP MM (Materials Management) is a module in SAP ERP that manages procurement processes and inventory management. IT Accurate Institute in Mumbai offers comprehensive training in SAP MM for effective utilization.",
      ],
      link: "/sap-mm",
    },
    {
      title: "SAP FICO",
      points: [
        "SAP FICO, offered by IT Accurate Institute Mumbai, encompasses financial accounting and controlling modules within the SAP ERP system, facilitating comprehensive financial management and reporting for organizations.",
      ],
      link: "/sap-fico",
    },
    {
      title: "SAP ABAP",
      points: [
        "SAP ABAP is a programming language used for developing applications within the SAP ecosystem. IT Accurate Institute in Mumbai is a training center specializing in SAP courses, including ABAP programming to equip professionals with the skills.",
      ],
      link: "/sap-abap",
    },
    {
      title: "SAP HCM",
      points: [
        "SAP HCM (Human Capital Management) is an integrated software suite by SAP that manages HR processes, from recruitment to payroll. IT Accurate Institute in Mumbai likely provides training in SAP HCM and related modules.",
      ],
      link: "/sap-hcm",
    },
    {
      title: "SAP SCM",
      points: [
        "SAP SCM (Supply Chain Management) is a software suite by SAP that integrates and optimizes key supply chain processes for enhanced efficiency. It enables businesses to manage procurement, production, and distribution seamlessly, ensuring streamlined operations.",
      ],
      link: "/sap-scm",
    },
    {
      title: "SAP ARIBA",
      points: [
        "SAP Ariba is a cloud-based procurement platform by SAP that streamlines and automates procurement processes, enhancing collaboration between buyers and suppliers. IT Accurate Institute Mumbai is an educational institution specializing in SAP training and certification.",
      ],
      link: "/sap-ariba",
    },
    {
      title: "SAP PP",
      points: [
        "SAP PP (Production Planning) is an SAP module that helps organizations manage and optimize their manufacturing processes, from planning and scheduling to execution. It integrates various business functions to streamline production.",
      ],
      link: "/sap-pp",
    },
    {
      title: "SAP SD",
      points: [
        "SAP SD (Sales and Distribution) is a module in SAP ERP that manages sales and customer service processes. It covers order-to-cash processes, including sales order processing, pricing, delivery, and billing. SAP SD to equip individuals with skills.",
      ],
      link: "/sap-sd",
    },
    {
      title: "SAP FIORI",
      points: [
        "SAP Fiori is a user experience (UX) design approach and set of design principles that enhance the usability and functionality of SAP applications. It provides a modern, responsive, and personalized user interface for a seamless and intuitive user experience in the SAP environment.",
      ],
      link: "/sap-fiori",
    },
    {
      title: "SAP QM",
      points: [
        "SAP QM (Quality Management) is a module in SAP ERP that facilitates quality control and assurance processes in manufacturing and production. It helps organizations manage quality planning, inspection, and certification, ensuring products meet specified standards and many more.",
      ],
      link: "/sap-qm",
    },
    {
      title: "SAP PM",
      points: [
        "SAP PM (Plant Maintenance) is a module in SAP ERP that helps organizations effectively manage and maintain their assets, equipment, and facilities. It covers processes such as preventive maintenance, work orders, and equipment tracking to optimize asset performance.",
      ],
      link: "/sap-pm",
    },
    {
      title: "SAP WM & EWM",
      points: [
        "SAP WM (Warehouse Management) focuses on efficient inventory management within a warehouse, while SAP EWM (Extended Warehouse Management) extends these capabilities with advanced features like labour management and slotting optimization for operations.",
      ],
      link: "/sap-wm-ewm",
    },
    {
      title: "SAP LE & SL",
      points: [
        "SAP LE (Logistics Execution) is a module that manages and optimizes warehouse and transportation processes, while SAP SL (Slotting) focuses on optimizing storage space and picking efficiency within a warehouse. Both modules contribute to streamlining supply chain operations in SAP systems.",
      ],
      link: "/sap-le-sl",
    },
  ];

  const roadmapData = [
    {
      title: "Introduction",
      points: [
        "What is SAP?",
        "What is SAP SCM?",
        "What is SAP HANA?",
        "SAP course",
        "SAP certification",
        "SAP jobs in india",
        "SAP jobs in mumbai",
      ],
    },
    {
      title: "Stakeholder Alignment",
      points: [
        "Engage key stakeholders across departments (procurement, logistics, production, etc.).",
        "Ensure alignment between business goals and SCM objectives.",
        "Communicate the benefits and impact of SAP SCM implementation.",
      ],
    },
    {
      title: "Data Clean-up and Migration",
      points: [
        "Perform a data quality assessment and clean up existing data.",
        "Develop a data migration strategy to seamlessly transition to SAP SCM.",
        "Ensure data accuracy and integrity for effective decision-making.   ",
      ],
    },
    {
      title: "Integration with Existing Systems",
      points: [
        "Identify integration points with other enterprise systems (ERP, CRM, etc.).",
        "Develop interfaces and integration solutions for seamless data flow.",
        "Test and validate data exchanges between SAP SCM and other systems.",
      ],
    },
    {
      title: "Pilot Implementation",
      points: [
        "Conduct a pilot implementation with a small-scale deployment.",
        "Gather feedback from users and address any issues.",
        "Refine configurations and processes based on pilot results.",
      ],
    },
    {
      title: "Full-Scale Rollout",
      points: [
        "Scale up the implementation to cover the entire organization.",
        "Monitor system performance and address any scalability issues.",
        "Provide ongoing support to users during the transition.",
      ],
    },
    {
      title: "Performance Monitoring and Optimization",
      points: [
        "Establish key performance indicators (KPIs) for SCM processes.",
        "Implement monitoring tools to track and analyze performance.",
        "Continuously optimize processes based on performance metrics.",
      ],
    },
    {
      title: "Resume preparation",
      points: [
        "Include keywords.",
        "How to prepare resume.",
        "How to prepare resume for freshers.",
        "Show impact.",
        "Include soft skills.",
        "Be unique.",
      ],
    },
  ];

  const syllabus = [
    {
      title: "Introduction to SAP SCM",
      subpoints: [
        "Overview of SAP SCM and its significance in business processes.",
        "Understanding the integration with other SAP modules.          ",
      ],
    },
    {
      title: "Supply Network Planning(SNP)",
      subpoints: [
        "SNP concepts and functionalities.",
        "Demand and supply planning.",
        "SNP optimization and simulation",
      ],
    },
    {
      title: "Advanced Planning & Optimization(APO)",
      subpoints: [
        "Overview of APO and its components.",
        "Core Interface (CIF) for data integration.",
        "APO master data and transaction data.",
      ],
    },
    {
      title: "Demand Planning(DP)",
      subpoints: [
        "Forecasting techniques and models.",
        "Collaborative demand planning.",
        "Lifecycle Planning.",
      ],
    },
    {
      title: "Integrated Business Planning(IBP)",
      subpoints: [
        "End-to-end planning processes.",
        "Real-time supply chain visibility.",
        "S&OP (Sales and Operations Planning) in IBP.",
      ],
    },
    {
      title: "Warehouse Management(WM)",
      subpoints: [
        "Warehouse structure and organization.",
        "Goods receipt and goods issue processes.",
        "Inventory management and optimization.",
      ],
    },
    {
      title: "Material Requirements Planning(MRP)",
      subpoints: [
        "MRP concepts and processes.",
        "MRP run and planning parameters.",
        "MRP evaluation and analysis.",
      ],
    },
  ];

  const keyFeatures = [
    { text: "120+ Hours course duration", icon: "fa-users" },
    { text: "100% Job oriented training", icon: "fa-user-check" },
    { text: "Industry expert faculties", icon: "fa-chalkboard-teacher" },
    { text: "Free demo class available", icon: "fa-calendar-alt" },
    { text: "Certification guidance", icon: "fa-comments" },
    { text: "Completed 200+ batches", icon: "fa-laptop-code" },
  ];

  const faqs = [
    {
      question: "What is SAP SCM?",
      answer:
        "SAP SCM (Supply Chain Management) is a suite of software that helps organizations optimize their supply chain including planning, purchasing, manufacturing and logistics.",
    },
    {
      question: "How does SAP SCM improve Supply chain visibility",
      answer:
        "SAP SCM affords real-time visibility into supply chain activities, allowing better tracking of inventory, demand, and order fame. This lets in quicker decisions to be made and decreases the chance of disruption.",
    },
    {
      question: "What is SAP APO in SCM?",
      answer:
        "SAP Advanced Planning and Optimization (APO) is part of SAP SCM that specializes in advanced supply chain making plans, supporting businesses beautify demand planning, manufacturing making plans, and optimization.",
    },
    {
      question: "How does SAP SCM support demand planning?",
      answer:
        "SAP SCM incorporates call for planning tools to analyze historic records, market trends, and different factors to forecast future demand appropriately. This allows organizations align their manufacturing and distribution with consumer requirements.",
    },
    {
      question: "What is SAP ECC in the context of SCM?",
      answer:
        "SAP ECC (Enterprise Central Component) is the middle software program that SAP SCM integrates with to offer cease-to-give up deliver chain control abilties, protecting numerous enterprise processes.",
    },
    {
      question: "How does SAP SCM enhance collaboration in the supply chain?",
      answer:
        "SAP SCM facilitates collaboration by permitting actual-time communique among distinct stakeholders within the supply chain. This includes providers, producers, vendors, and stores, fostering higher coordination and responsiveness.",
    },
    {
      question: "What is SAP S/4HANA and its role in SCM?",
      answer:
        "SAP S/4HANA is the next-generation ERP (Enterprise Resource Planning) suite from SAP. It performs a vital function in SCM via providing a unified platform for handling deliver chain procedures with more desirable velocity, efficiency, and real-time analytics.",
    },
    {
      question: "How does SAP SCM address inventory optimization?",
      answer:
        "SAP SCM allows optimize stock tiers via providing equipment for higher call for forecasting, order control, and stock tracking. This minimizes excess stock, reduces carrying fees, and improves general deliver chain performance.",
    },
    {
      question: "What is SAP IBP, and how does it differ from SAP APO in SCM?",
      answer:
        "SAP Integrated Business Planning (IBP) is a cloud-based solution that supersedes SAP APO. IBP gives better capabilities for integrated making plans, actual-time analytics, and better collaboration across the entire supply chain.",
    },
    {
      question:
        "How can SAP SCM contribute to sustainability in the supply chain?",
      answer:
        "SAP SCM supports sustainability tasks by way of optimizing transportation routes, reducing waste, and enabling higher visibility into the environmental effect of deliver chain sports. This allows agencies make extra eco-friendly choices.",
    },
  ];

  const whatsData = [
    {
      title: "About SAP SCM Training Course",
      points: [
        "SAP SCM (Supply Chain Management) is a software suite designed to optimize and streamline end-to-end supply chain processes.",
        "Enables efficient planning, execution, coordination, and monitoring of all supply chain activities.",
        "Integrates with other SAP modules, such as ERP, to provide a comprehensive business solution.",
        "Facilitates demand planning to ensure optimal inventory levels and meet customer demand.",
        "Supports collaborative forecasting and demand sensing for accurate predictions.",
        "Manages procurement processes, including supplier relationship management and procurement analytics.",
        "IT Accurate training provides SAP MM training in offline and online mode. Starting end user, consulting, implementation, support with real-time SAP project-based training.",
        "IT background, non-IT background, freshers, and experienced individuals can start their career in SAP irrespective of their background.",
        "IT Accurate is the best training institute in Mumbai with 100% job opportunities.",
      ],
    },
  ];
  
  return (
    <div className="devops-page">
      <div className="content-container">
        <div className="devops-circle devops-circle-bottom-left"></div>
        <div className="left-content">
          <h1>Best SAP SCM Training</h1>
          <p>
            IT Accurate offers you the best SAP SCM (Supply Chain Management)
            training in Mumbai for freshers and experienced candidates in
            Mumbai, with expert guidance and 100% guaranteed placement
            assistance. Our SAP training course covers every technical topic in
            detail, and the student is left with real-time hands-on experience.
          </p>
          <br />
          <p>
            Our practical, job-oriented training program will not only provide
            you with a certificate but also with knowledge equivalent to around
            2+ years of field experience. We value your time as much as ours.
            Hence, we have designed a training program to train you in SAP SCM
            with S/4 HANA in just 4 months.
          </p>

          <ul className="points-list">
            <li>End user 1 month (ECC / S4 HANA)</li>
            <li>Configuration 2 months(ECC/S4 HANA)</li>
            <li>Project 1 month</li>
          </ul>
          <h3>All the topics will be covered in detail and also include:</h3>
          <ul className="points-list">
            <li>
              <FontAwesomeIcon icon={faFileAlt} /> Resume preparation
            </li>
            <li>
              <FontAwesomeIcon icon={faChalkboardTeacher} /> Interview practice
            </li>
            <li>
              <FontAwesomeIcon icon={faBriefcase} /> 6-months internship
            </li>
            <li>
              <FontAwesomeIcon icon={faCheckCircle} /> 100% job opportunities
              guaranteed program
            </li>
          </ul>
        </div>
        <div className="form-contact">
          <ContactForm title="Get in Touch" submitText="Submit" />
        </div>
      </div>

      {/* <div className="video-section">
                <div className="video-container" controls ref={videoRef}></div>
            </div> */}
            <StickyNavbar name="SapSCM" />

      <WhatsDevOps whatsData={whatsData} />
      <Roadmap heading="Roadmap to learn SAP SCM" roadmapData={roadmapData} />
      <Syllabus heading="SAP SCM Training Syllabus" Syllabus={syllabus} />
      <KeyFeatures heading="SAP HCM Training" keyFeatures={keyFeatures} />
      <section id="certification" className="devops-certification-section">
        <h2 className="certification-heading">SAP SCM CERTIFICATION</h2>
        <div className="certification-content">
          <div className="certification-points">
          <ul>
    <li>
        IT Accurate training certification will serve as proof that the courses were completed by IT Accurate.
    </li>
    <li>
        The SAP S/4HANA certification offered by IT Accurate will equip you with valuable skills, enhancing your competitiveness in the job market.
    </li>
    <li>
        IT Accurate provides comprehensive guidance for your SAP S/4HANA global certification, ensuring a 100% passing guarantee in examinations such as SAP Certification, SAP S/4HANA Platform Development Certification, and various other global exams.
    </li>
</ul>

          </div>
          <div className="certification-image">
            <img src={certificateImg} alt="DevOps Certification" />
          </div>
        </div>
      </section>
      <Roadmap heading="SAP modules" roadmapData={modulesData} />
      <CourseOpportunities pageName="SAP_SCM" />
      <Faq Faqs={faqs} />
      <Alumni />
    </div>
  );
};

export default SAPSCM;
