import React from 'react';
import { FaStar, FaStarHalfAlt } from 'react-icons/fa';

const RatingCard = () => {
  const brands = [
    { logo: 'https://images2.goabroad.com/image/upload/h_208,w_272/v1/images2/goabroad-logo.webp', name: 'GoAbroad', rating: 4.5, score: 4.5 },
    { logo: 'https://www.cdnlogo.com/logos/u/6/urbanpro.svg', name: 'UrbanPro', rating: 5, score: 5.0 },
    { logo: 'https://www.experiment.org/wp-content/uploads/2021/08/GoOverseas-logo.png', name: 'GO Overseas', rating: 4.5, score: 4.5 },
    { logo: 'https://newsmantra.in/wp-content/uploads/2023/11/Group-2242.png', name: 'AmbitionBox', rating: 4.6, score: 4.6 },
    { logo: 'https://brandlogo.org/wp-content/uploads/2024/06/Google-Logo.png.webp', name: 'Google', rating: 5, score: 5.0 },
    { logo: 'https://www.cdnlogo.com/logos/g/56/glassdoor.svg', name: 'Glassdoor', rating: 4.6, score: 4.6 },
    { logo: 'https://edugorilla.com/wp-content/uploads/2016/10/logo-1.png', name: 'EduGorilla', rating: 4.5, score: 4.5 },
    { logo: 'https://m.mouthshut.com/images/logo3x.png', name: 'MouthShut', rating: 4.5, score: 4.5 },
  ];

  return (
    <div className="mx-auto px-6 py-10">
      <h2 className="text-4xl font-bold text-center text-gray-800 mb-8">
        We are one of the Most Trusted Brands!
      </h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
        {brands.map((brand, index) => (
          <div
            key={index}
            className="bg-white justify-between h-[8rem] shadow-lg rounded-md p-4 flex flex-row items-center hover:shadow-xl transition-shadow"
          >
            <img
              src={brand.logo}
              alt={brand.name}
              className="w-32 h-28 object-contain mr-4"
            />

            {/* Rating Stars and Score */}
            <div className="flex flex-col gap-2 items-center">
              {/* Stars */}
              <div className="flex">
                {[...Array(Math.floor(brand.rating))].map((_, i) => (
                  <FaStar key={i} className="text-yellow-400 text-lg" />
                ))}
                {brand.rating % 1 !== 0 && <FaStarHalfAlt className="text-yellow-400 text-lg" />}
              </div>

              {/* Rating Score */}
              <div
                style={{
                  background: "linear-gradient(251deg, rgba(28, 152, 237, 1) 35%, rgba(24, 72, 160, 1) 100%)"
                }}
                className="text-white text-md font-semibold px-4 py-1 rounded-md"
              >
                {brand.score}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RatingCard;
