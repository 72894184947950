import React from 'react';
import "../css/carrerdev.css";
import img1 from '../assets/Carrerdev/Carrer.webp';

const CarrerDev = () => {
  return (
    <div className="carrer-dev-container">
      <h1>Career Development</h1>
      <div className="carrer-content">
        <div className="carrer-text">
          <p>
            <strong>IT Accurate Institute</strong> offers a comprehensive pathway for individual growth, focusing on two key pillars of modern education: Competency Development and <strong>Career Advancement</strong>.
          </p>
          <p>
            Competency Development at IT Accurate is designed to equip learners with the skills required to excel in today’s dynamic job market, while also preparing them for the future. This journey includes:
          </p>
          <ol>
            <li>Two-Way Live Training</li>
            <li>Real-World Projects</li>
            <li>Student Engagement & Events</li>
            <li>Expert-Led Classes</li>
            <li>E-Learning</li>
          </ol>
          <p>
            Career Development is aimed at providing the tools necessary to secure a successful career or to elevate one’s professional standing. It covers:
          </p>
          <ol>
            <li>IT Accurate Certification</li>
            <li>Internship</li>
            <li>Placement Assistance</li>
          </ol>
          <div className="carrer-buttons">
            <a
              href="https://wa.me/1234567890"
              target="_blank"
              rel="noopener noreferrer"
              className="whatsapp-button"
            >
              WhatsApp Us
            </a>
            <a
              href="tel:+1234567890"
              className="expert-button"
            >
              Talk to Expert
            </a>
          </div>
        </div>
        <div className="carrer-image">
          <img src={img1} alt="Career Development Framework" />
        </div>
      </div>
    </div>
  );
};

export default CarrerDev;
