import React, { useState } from 'react';
import '../css/FeesDetails.css';
import {
    Select,
    MenuItem,
    Button,
    Typography,
    Box,
    RadioGroup,
    FormControlLabel,
    Radio,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Grid,
} from '@mui/material';
import Marquee from 'react-fast-marquee';
import { courseData } from '../courses';
import hdfcLogo from '../assets/New folder/hdfclogo.jpg';
import visaLogo from '../assets/New folder/VISA.png'; 
import masterCardLogo from '../assets/New folder/master-card.jpg';
import amexLogo from '../assets/New folder/AMEX-Card.png';
import SBIBANK from '../assets/New folder/SBI.webp';
import AXISBANK from '../assets/New folder/AXISBANK.png';
import BOB from '../assets/New folder/BOB.png';

const calculateEMI = (principal, rate, months) => {
    const monthlyRate = rate / (12 * 100);
    const emi = (principal * monthlyRate * Math.pow(1 + monthlyRate, months)) /
        (Math.pow(1 + monthlyRate, months) - 1);
    return emi.toFixed(2);
};

const FeesDetails = () => {
    const [selectedCourse, setSelectedCourse] = useState('');
    const [showEMI, setShowEMI] = useState(false);
    const [selectedEMI, setSelectedEMI] = useState('');

    const handleCourseChange = (event) => {
        setSelectedCourse(event.target.value);
        setShowEMI(false);
        setSelectedEMI('');
    };

    const handleEMIChange = (event) => {
        setSelectedEMI(event.target.value);
    };

    const handleShowEMI = () => {
        setShowEMI(true);
    };

    const course = courseData.find((course) => course.id === selectedCourse);

    return (
        <Box
            sx={{
                padding: '20px',
                margin: '20px auto',
                backgroundColor: '#eaf4fc',
                position: 'relative',
                width: { xs: '95%', sm: '80%', md: '70%' },
                minHeight: '30vh',
                color: '#001f3f',
                border: '2px solid #1976d2',
                borderRadius: '8px',
                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                
            }}
        >
            <Grid container spacing={1} alignItems="center">
                <Grid item xs={8}>
                    <Typography variant="h5" gutterBottom sx={{ color: '#001f3f' }}>
                        Select Course
                    </Typography>
                </Grid>
                <Grid item xs={4} container justifyContent="flex-end">
                    <img
                        src={hdfcLogo}
                        alt="HDFC Logo"
                        style={{ width: '100%', height: 'auto', marginTop: '-1rem', maxWidth: '180px' }}
                    />
                </Grid>
            </Grid>

            <Select
                value={selectedCourse}
                onChange={handleCourseChange}
                displayEmpty
                fullWidth
                sx={{
                    backgroundColor: '#f7fbff',
                    borderRadius: '8px',
                    marginBottom: '20px',
                    color: '#001f3f',
                    '&:focus': {
                        borderColor: '#1976d2',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#1976d2',
                    },
                }}
            >
                <MenuItem value="" disabled>
                    Select a Course
                </MenuItem>
                {courseData.map((course) => (
                    <MenuItem key={course.id} value={course.id}>
                        {course.name}
                    </MenuItem>
                ))}
            </Select>

            {course && (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography variant="h6" sx={{ color: '#001f3f' }}>
                        Price: ₹{course.price}
                    </Typography>
                    <Button
                        variant="contained"
                        size="small"
                        sx={{
                            backgroundColor: '#1976d2',
                            '&:hover': { backgroundColor: '#1565c0' },
                            color: '#fff',
                            padding: '5px 10px',
                        }}
                        onClick={handleShowEMI}
                    >
                        Show EMI Plans
                    </Button>
                </Box>
            )}

            {showEMI && course && (
                <Box sx={{ mt: 3 }}>
                    <Typography variant="h6" sx={{ color: '#001f3f' }} gutterBottom>
                        Select EMI Plan:
                    </Typography>
                    <RadioGroup
                        row
                        value={selectedEMI}
                        onChange={handleEMIChange}
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            backgroundColor: '#f7fbff',
                            padding: '10px',
                            border: '1px solid #1976d2',
                            borderRadius: '10px',
                        }}
                    >
                        {course.emis.map((emi, index) => (
                            <FormControlLabel
                                key={index}
                                value={emi.duration}
                                control={<Radio sx={{ color: '#1976d2' }} />}
                                label={`${emi.duration} months at ${emi.interest}%`}
                                sx={{ color: '#001f3f' }}
                            />
                        ))}
                    </RadioGroup>
                </Box>
            )}

            {selectedEMI && course && (
                <Table
                    sx={{
                        mt: 3,
                        backgroundColor: '#f7fbff',
                        border: '1px solid #1976d2',
                        borderRadius: '10px',
                        width: '100%',
                    }}
                >
                    <TableHead>
                        <TableRow sx={{ backgroundColor: '#1976d2' }}>
                            <TableCell sx={{ color: '#fff' }}>Duration</TableCell>
                            <TableCell sx={{ color: '#fff' }}>Interest Rate (%)</TableCell>
                            <TableCell sx={{ color: '#fff' }}>Monthly Payment (₹)</TableCell>
                            <TableCell sx={{ color: '#fff' }}>Total Interest (₹)</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {course.emis
                            .filter((emi) => emi.duration === selectedEMI)
                            .map((emi, index) => {
                                const monthlyPayment = calculateEMI(course.price, emi.interest, parseInt(emi.duration));
                                const totalInterest = (
                                    monthlyPayment * parseInt(emi.duration) - course.price
                                ).toFixed(2);
                                return (
                                    <TableRow key={index}>
                                        <TableCell sx={{ color: '#001f3f' }}>{emi.duration}</TableCell>
                                        <TableCell sx={{ color: '#001f3f' }}>{emi.interest}</TableCell>
                                        <TableCell sx={{ color: '#001f3f' }}>{monthlyPayment}</TableCell>
                                        <TableCell sx={{ color: '#001f3f' }}>{totalInterest}</TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            )}

            <Typography variant="h6" sx={{ mt: 4, color: '#001f3f', textAlign: 'center' }}>
                We accept all payment modes and cards.
            </Typography>

            <Marquee
                direction="right"
                gradient={false}
                speed={100}
                style={{ margin: '20px auto', width: '80%' }}
            >
                <img src={visaLogo} alt="Visa" style={{ width: '50px', margin: '0 20px' }} />
                <img src={masterCardLogo} alt="MasterCard" style={{ width: '100px', margin: '0 20px' }} />
                <img src={hdfcLogo} alt="HDFC bank" style={{ width: '100px', margin: '0 20px' }} />
                <img src={amexLogo} alt="American Express" style={{ width: '100px', margin: '0 20px' }} />
                <img src={SBIBANK} alt="SBI bank" style={{ width: '100px', margin: '0 20px' }} />
                <img src={BOB} alt="Bank of Baroda" style={{ width: '100px', margin: '0 20px' }} />
                <img src={AXISBANK} alt="Axis Bank" style={{ width: '100px', margin: '0 20px' }} />
            </Marquee>
        </Box>
    );
};

export default FeesDetails;
