import React, { useEffect, useRef } from "react";
import "../../css/DevOps.css";
import certificateImg from '../../assets/Certificates/IT accurate certificate.png';
// import IMG from '../../assets/logo.svg'
import Roadmap from "../../components/Roadmap";
import WhatsDevOps from "../../components/WhatsDevOps";
// import PageForm from '../../components/PageForm';
import Syllabus from "../../components/Syllabus";
import KeyFeatures from "../../components/KeyFeatures";
import Faq from "../../components/Faq";
import "slick-carousel/slick/slick.css"; // Importing slick carousel styles
import "slick-carousel/slick/slick-theme.css"; // Importing slick theme styles
// import dev1 from '../../assets/Projects/Devops/DEV1.png';
// import dev2 from '../../assets/Projects/Devops/DEV2.png';
// import dev3 from '../../assets/Projects/Devops/DEV3.png';
// import dev4 from '../../assets/Projects/Devops/DEV4.png';
// import dev5 from '../../assets/Projects/Devops/DEV5.png';
// import dev6 from '../../assets/Projects/Devops/DEV6.png';
// import dev7 from '../../assets/Projects/Devops/DEV7.png';
// import dev8 from '../../assets/Projects/Devops/DEV8.png';
// import rev1 from '../../assets/Reviews/DEVOPS/devrev1.png';
// import rev2 from '../../assets/Reviews/DEVOPS/devrev2.png';
// import rev3 from '../../assets/Reviews/DEVOPS/devrev3.png';
// import rev4 from '../../assets/Reviews/DEVOPS/devrev4.png';
// import rev5 from '../../assets/Reviews/DEVOPS/devrev5.png';
import StickyNavbar from "../../components/StickyNavbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faFileAlt,
  faChalkboardTeacher,
  faBriefcase,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
// import SideDropdown from '../../components/SideDropdown';
import MarqueeLogos from "../../components/MarqueeLogos";
import ContactForm from "../../components/ContactForm";
import CourseOpportunities from "../../components/CourseOpportunities";
const SAPABAP = () => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);

  // useEffect(() => {
  //     // Function to create the YouTube player
  //     const createPlayer = () => {
  //         if (window.YT && window.YT.Player) {
  //             playerRef.current = new window.YT.Player(videoRef.current, {
  //                 videoId: 'i3HdVH6gdfE', // Updated YouTube video ID
  //                 events: {
  //                     onReady: (event) => {
  //                         event.target.mute(); // Mute the video to allow autoplay
  //                         // event.target.playVideo();
  //                     },
  //                 },
  //                 playerVars: {
  //                     loop: 1,
  //                     controls: 0, // Hide video controls
  //                     modestbranding: 1, // Minimal YouTube branding
  //                     playlist: 'i3HdVH6gdfE', // Required for looping
  //                 },
  //             });
  //         }
  //     };

  //     // Load YouTube IFrame API if it's not already loaded
  //     if (!window.YT) {
  //         const tag = document.createElement('script');
  //         tag.src = 'https://www.youtube.com/iframe_api';
  //         const firstScriptTag = document.getElementsByTagName('script')[0];
  //         firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

  //         // Set up callback for when the API is ready
  //         window.onYouTubeIframeAPIReady = () => {
  //             createPlayer();
  //         };
  //     } else {
  //         createPlayer();
  //     }
  // }, []);

  const roadmapData = [
    {
      title: "Introduction",
      points: [
        "What is SAP?",
        "What is SAP ABAP?",
        "What is SAP HANA?",
        "SAP course",
        "SAP certification",
        "SAP jobs in india",
        "SAP jobs in mumbai",
      ],
    },
    {
      title: "ABAP Object-Oriented Programming (OOP)",
      points: [
        "Understand and implement ABAP OOP concepts.",
        "Create and use classes, interfaces, and inheritance.",
      ],
    },
    {
      title: "Debugging and Performance Optimization",
      points: [
        "Master ABAP debugging techniques.",
        "Learn strategies for optimizing ABAP code and database queries.",
      ],
    },
    {
      title: "ALV(ABAP List Viewer) Programming",
      points: [
        "Develop reports using ALV Grid and ALV List.",
        "Customize ALV output according to business requirements.",
      ],
    },
    {
      title: "Web Dynpro ABAP",
      points: [
        "Learn Web Dynpro ABAP for developing web-based applications.",
        "Understand MVC (Model-View-Controller) architecture in Web Dynpro",
      ],
    },
    {
      title: " ABAP Unit Testing",
      points: [
        "Implement unit testing using ABAP Unit.",
        "Understand the importance of test-driven development (TDD).",
      ],
    },
    {
      title: "SAP Gateway and OData Services",
      points: [
        "Learn SAP Gateway for building OData services.",
        "Enable communication between SAP systems and external applications.",
      ],
    },
    {
      title: "Resume preparation",
      points: [
        "Include keywords.",
        "How to prepare resume.",
        "How to prepare resume for freshers.",
        "Show impact.",
        "Include soft skills.",
        "Be unique.",
      ],
    },
  ];

  const modulesData = [
    {
      title: "SAP",
      points: [
        "IT Accurate Training in Mumbai offers expert SAP training, empowering you to efficiently manage SAP systems and ensure seamless operations.",
      ],
      link: "/best-sap-training",
    },
    {
      title: "SAP BASIS",
      points: [
        "IT Accurate Training in Mumbai offers expert SAP BASIS training, empowering you to efficiently manage SAP systems and ensure seamless operations.",
      ],
      link: "/sap-basis-training-in-mumbai",
    },
    {
      title: "SAP MM",
      points: [
        "SAP MM (Materials Management) is a module in SAP ERP that manages procurement processes and inventory management. IT Accurate Institute in Mumbai offers comprehensive training in SAP MM for effective utilization.",
      ],
      link: "/sap-mm",
    },
    {
      title: "SAP FICO",
      points: [
        "SAP FICO, offered by IT Accurate Institute Mumbai, encompasses financial accounting and controlling modules within the SAP ERP system, facilitating comprehensive financial management and reporting for organizations.",
      ],
      link: "/sap-fico",
    },
    {
      title: "SAP ABAP",
      points: [
        "SAP ABAP is a programming language used for developing applications within the SAP ecosystem. IT Accurate Institute in Mumbai is a training center specializing in SAP courses, including ABAP programming to equip professionals with the skills.",
      ],
      link: "/sap-abap",
    },
    {
      title: "SAP HCM",
      points: [
        "SAP HCM (Human Capital Management) is an integrated software suite by SAP that manages HR processes, from recruitment to payroll. IT Accurate Institute in Mumbai likely provides training in SAP HCM and related modules.",
      ],
      link: "/sap-hcm",
    },
    {
      title: "SAP SCM",
      points: [
        "SAP SCM (Supply Chain Management) is a software suite by SAP that integrates and optimizes key supply chain processes for enhanced efficiency. It enables businesses to manage procurement, production, and distribution seamlessly, ensuring streamlined operations.",
      ],
      link: "/sap-scm",
    },
    {
      title: "SAP ARIBA",
      points: [
        "SAP Ariba is a cloud-based procurement platform by SAP that streamlines and automates procurement processes, enhancing collaboration between buyers and suppliers. IT Accurate Institute Mumbai is an educational institution specializing in SAP training and certification.",
      ],
      link: "/sap-ariba",
    },
    {
      title: "SAP PP",
      points: [
        "SAP PP (Production Planning) is an SAP module that helps organizations manage and optimize their manufacturing processes, from planning and scheduling to execution. It integrates various business functions to streamline production.",
      ],
      link: "/sap-pp",
    },
    {
      title: "SAP SD",
      points: [
        "SAP SD (Sales and Distribution) is a module in SAP ERP that manages sales and customer service processes. It covers order-to-cash processes, including sales order processing, pricing, delivery, and billing. SAP SD to equip individuals with skills.",
      ],
      link: "/sap-sd",
    },
    {
      title: "SAP FIORI",
      points: [
        "SAP Fiori is a user experience (UX) design approach and set of design principles that enhance the usability and functionality of SAP applications. It provides a modern, responsive, and personalized user interface for a seamless and intuitive user experience in the SAP environment.",
      ],
      link: "/sap-fiori",
    },
    {
      title: "SAP QM",
      points: [
        "SAP QM (Quality Management) is a module in SAP ERP that facilitates quality control and assurance processes in manufacturing and production. It helps organizations manage quality planning, inspection, and certification, ensuring products meet specified standards and many more.",
      ],
      link: "/sap-qm",
    },
    {
      title: "SAP PM",
      points: [
        "SAP PM (Plant Maintenance) is a module in SAP ERP that helps organizations effectively manage and maintain their assets, equipment, and facilities. It covers processes such as preventive maintenance, work orders, and equipment tracking to optimize asset performance.",
      ],
      link: "/sap-pm",
    },
    {
      title: "SAP WM & EWM",
      points: [
        "SAP WM (Warehouse Management) focuses on efficient inventory management within a warehouse, while SAP EWM (Extended Warehouse Management) extends these capabilities with advanced features like labour management and slotting optimization for operations.",
      ],
      link: "/sap-wm-ewm",
    },
    {
      title: "SAP LE & SL",
      points: [
        "SAP LE (Logistics Execution) is a module that manages and optimizes warehouse and transportation processes, while SAP SL (Slotting) focuses on optimizing storage space and picking efficiency within a warehouse. Both modules contribute to streamlining supply chain operations in SAP systems.",
      ],
      link: "/sap-le-sl",
    },
  ];

  const syllabus = [
    {
      title: "Introduction",
      subpoints: [
        "What is SAP?",
        "History & Features of SAP",
        "SAP R/2 Architecture (Limitations of R/2 Architecture)",
        "SAP R/3 Architecture (Types of work processes)",
        "SAP R/3 Application Modules",
        "SAP Landscape.",
      ],
    },
    {
      title: "Introduction to ABAP/4",
      subpoints: [
        "What is ABAP?",
        "Logon to SAP Environment",
        "Transaction Codes",
        "Transport Organizer",
        "Package",
        "Write Statements",
        "System Variables",
        "ABAP Programming Language – Data Types – TYPE Keyword – DATA Keyword",
        "Programming Structure – Work area – Internal Table – Structure Etc.",
        "ABAP/4 Editor ( SE38 )",
        "Steps for Creating a Program",
        "Flow Of Data in R/3 Architecture",
        "Debugging",
        "– Break-Point(Static, Dynamic)",
        "Watch-Point",
        "Sub objects in ABAP Editor",
        "SQL Statements – Open SQL",
        "Native SQL",
        "Selection-Screen Introduction",
        "Selection-screen Statement",
        "Parameter Statement",
        "Select-options Statement",
        "Message Handling",
        "Introduction to Inner Join & For All Entries In",
        "Modularization Techniques",
        "Subroutine",
        "Function Module",
        "Field-Symbol",
        "Macro",
        "String Operations",
        "Control-Break Statements",
      ],
    },
    {
      title: "ABAP/Data Dictionary (DDIC)",
      subpoints: [
        "Introduction to Data Dictionary Introduction",
        "Sub objects of Data Dictionary",
        "Database Table",
        "View – Data Type",
        "Type Group",
        "Domain",
        "Search Help",
        "Lock Object",
        "Structure",
        "Primary Key And Foreign Key",
        "Table Maintenance Generator",
      ],
    },
    {
      title: "Business Flow",
      subpoints: ["MM", "SD", "PP", "FI", "CO", "HR"],
    },
    {
      title: "ABAP Reports",
      subpoints: [
        "Classical Reporting",
        "Interactive Reporting",
        "ALV Reporting",
        "Classical ALV",
        "Interactive ALV",
        "Blocked ALV",
        "Hierarchical ALV",
        "LDB (Logical Database) Reporting",
        "SQL Query",
      ],
    },
    {
      title: "ABAP Transaction",
      subpoints: [
        "Introduction to Module-Pool/Dialog Programming",
        "Components of Screen",
        "Events In Module Pool",
        "Process Before Output (PBO)",
        "Process AfterInput (PAI)",
        "Process (POV) On Value-Request",
        "Process On HELP-Request (POH)",
        "Dynamic Screens",
        "Leave Screen",
        "Leave To Screen",
        "Call Screen",
        "Set Screen",
        "Elements in Screen Layout",
        "Sub screen",
        "Table Control",
        "Tab Strip Control",
        "Table Control (Wizard)",
        "Single/Multiple Field Validation",
        "LUW (Logical Unit Work)",
      ],
    },
    {
      title: "Batch Data Communication",
      subpoints: [
        "Introduction to BDC Technique",
        "BDC Methods",
        "Session Method",
        "Call Transaction Method (CTM)",
        "Legacy System Migration Workbench Methods",
        "Batch Input Recording (Session)",
        "IDOC BAPI",
        "Direct Input Method",
        "BDC With Table Control",
        "File Handling",
        "Application Server",
        "Presentation Server",
      ],
    },
    {
      title: "SAP Scripts",
      subpoints: [
        "Introduction to SAP Scripts",
        "Components of SAP Scripts",
        "Function Module In Scripts",
        "Crating Driver Program",
        "Logo/Graphics in Scripts",
        "Modifying Standard SAP Scripts",
      ],
    },
    {
      title: "Smart Forms",
      subpoints: [
        "Introduction to Smart Forms",
        "Smart Forms Architecture",
        "Smart Style",
        "Component of Smart Forms",
        "Creating Print Program",
        "Differences Between Smart Forms & Scripts",
      ],
    },
    {
      title: "Cross Applications",
      subpoints: [
        "Introduction to Distributed Environment",
        "Introduction to Cross Applications",
      ],
    },
    {
      title: "ALE (Application Linking Enabling)",
      subpoints: ["ALE Overview", "ALE Architecture", "Logical System"],
    },
    {
      title: "IDOC (Intermediate Document)",
      subpoints: [
        "Introduction to IDOC",
        "Component of IDOC",
        "Creating",
        "Message Type",
        "Model View / Customer Distribution Model",
        "PORT",
        "Generate Partner Profile",
        "Assigning IDOC Type to Message Type",
        "Types of IDOCs",
        "Standard IDOC",
        "Custom IDOC",
        "Extended IDOC",
      ],
    },
    {
      title: "Enhancement",
      subpoints: [
        "Introduction to Enhancement",
        "Types of Enhancement",
        "Overview User-Exits & Customer Exits",
        "Types of User-Exits & Customer-Exits",
      ],
    },
  ];

  const faqs = [
    {
      question: "What is SAP ABAP?",
      answer:
        "SAP ABAP (Advanced Business Application Programming) is a high-level programming language used for developing applications in the SAP.",
    },
    {
      question: "What is the difference between ABAP and SAP?",
      answer:
        "ABAP is the programming language used to develop applications within the SAP system. SAP, on the other hand, is the enterprise resource planning software.",
    },
    {
      question: "How to declare variables in ABAP?",
      answer: "Variables in ABAP are declared using the data statement.",
    },
    {
      question: "What is a Data Dictionary in SAP ABAP?",
      answer:
        "The Data Dictionary in SAP ABAP is a central repository that stores and manages data definitions (metadata) used in the SAP system.",
    },
    {
      question:
        "Explain the difference between a transparent table and a pooled table in SAP?",
      answer:
        "Transparent tables store application data directly, while pooled tables store control data used by the system.",
    },
    {
      question: "How to write a SELECT statement in ABAP?",
      answer:
        "The basic syntax for a SELECT statement in ABAP is: SELECT * FROM table INTO wa WHERE condition.",
    },
    {
      question: "What is a BDC (Batch Data Communication) in SAP ABAP?",
      answer:
        "BDC is a technique used for transferring large amounts of data from external systems into the SAP system. It involves the use of batch input programs.",
    },
    {
      question: "Explain SAP ABAP ALV (ABAP List Viewer).",
      answer:
        "SAP ABAP ALV is a set of function modules and methods that simplify the display of lists and tabular data in ABAP programs.",
    },
    {
      question: "How to handle errors in SAP ABAP?",
      answer:
        "Errors in ABAP can be handled using statements like TRY, CATCH, and ENDTRY for exception handling",
    },
    {
      question: "What is the purpose of the ABAP Dictionary?",
      answer:
        "The ABAP Dictionary is used to define and manage database objects like tables, views, and indexes in the SAP system.",
    },
  ];

  const whatsData = [
    {
      title: "What is SAP ABAP ?",
      points: [
        "SAP (Advanced Business Application Programming) is the primary programming language supported on the SAP NetWeaver ABAP application server platform and applications that run on it, such as SAP ERP (formerly R/3), S/4HANA and CRM.",
        "ABAP helps customise workflows for financial accounting, materials management, asset management, and all other modules of SAP. SAP’s current development platform NetWeaver also supports both ABAP and Java.",
        "There are 2 types of SAP ERP system modules: Functional Modules and Technical Modules. All SAP modules integrate with each other with functionality and provide the best solution for a business.",
        "IT Accurate training provides SAP ABAP training in offline and online mode. Starting end user, consulting, implementation, support with real-time SAP project-based training.",
        "IT background, non-IT background, freshers, and experienced individuals can start their career in SAP irrespective of their background.",
        "IT Accurate is the best training institute in Mumbai with 100% job opportunities.",
      ],
    },
  ];

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // const imageList = [
  //     { Image: dev1, alt: "Award 1" },
  //     { Image: dev2, alt: "Award 2" },
  //     { Image: dev3, alt: "Award 3" },
  //     { Image: dev4, alt: "Award 4" },
  //     { Image: dev5, alt: "Award 5" },
  //     { Image: dev6, alt: "Award 6" },
  //     { Image: dev7, alt: "Award 7" },
  //     { Image: dev8, alt: "Award 8" },
  // ];

  // const reviewList = [
  //     { Image: rev1, alt: "rev1" },
  //     { Image: rev2, alt: "rev1" },
  //     { Image: rev3, alt: "rev1" },
  //     { Image: rev4, alt: "rev1" },
  //     { Image: rev5, alt: "rev1" },
  // ]
  const keyFeatures = [
    { text: "Limited Students Batch", icon: "fa-users" },
    { text: "Personalised Attention", icon: "fa-user-check" },
    { text: "Highly Qualified Teachers", icon: "fa-chalkboard-teacher" },
    { text: "Flexible Batch Timings", icon: "fa-calendar-alt" },
    { text: "Interactive Learning", icon: "fa-comments" },
    { text: "Live Projects", icon: "fa-laptop-code" },
    { text: "Career Support", icon: "fa-briefcase" },
    { text: "Job Oriented Training", icon: "fa-graduation-cap" },
  ];
  return (
    <div className="devops-page">
      {/* <SideDropdown roadmapData={modulesData} /> */}
      <div className="content-container">
        <div className="devops-circle devops-circle-bottom-left"></div>
        <div className="left-content">
          <h1>Best SAP ABAP Training</h1>
          <p>
            IT Accurate offers the best SAP ABAP (Advanced Business Application
            Programming) training in Mumbai for both freshers and experienced
            candidates, featuring expert guidance and a 100% job placement
            guarantee. Our SAP training course thoroughly covers all technical
            topics, ensuring students gain real-time, hands-on experience.
          </p>
          <br />
          <p>
            Our practical, job-oriented training program not only awards you a
            certificate but also equips you with knowledge equivalent to
            approximately 2+ years of industry experience. We value your time as
            much as our own, which is why we’ve designed our SAP ABAP training
            with S/4 HANA to be completed in just 4 months.
          </p>

          <ul className="points-list">
            <li>End user 1 month (ECC / S4 HANA)</li>
            <li>Configuration 2 months(ECC/S4 HANA)</li>
            <li>Project 1 month</li>
          </ul>
          <h3>All the topics will be covered in detail and also include:</h3>
          <ul className="points-list">
            <li>
              <FontAwesomeIcon icon={faFileAlt} /> Resume preparation
            </li>
            <li>
              <FontAwesomeIcon icon={faChalkboardTeacher} /> Interview practice
            </li>
            <li>
              <FontAwesomeIcon icon={faBriefcase} /> 6-months internship
            </li>
            <li>
              <FontAwesomeIcon icon={faCheckCircle} /> 100% job opportunities
              guaranteed program
            </li>
          </ul>
        </div>
        <div className="form-contact">
          <ContactForm title="Get in Touch" submitText="Submit" />
        </div>
      </div>
      {/* <div className="video-section">
                <div className="video-container" controls ref={videoRef}></div>
            </div> */}
             <StickyNavbar name="SapABAP" />
      <WhatsDevOps whatsData={whatsData} />

      <Roadmap heading="Roadmap to Learn SAP ABAP" roadmapData={roadmapData} />
      <Syllabus heading="SAP ABAP Training Syllabus" Syllabus={syllabus} />
      <KeyFeatures heading="SAP ABAP Training" keyFeatures={keyFeatures} />

      <section id="certification" className="devops-certification-section">
        <h2 className="certification-heading">SAP ABAP CERTIFICATION</h2>
        <div className="certification-content">
          <div className="certification-points">
            <ul>
              <li>
                IT Accurate training certification will serve as proof that the
                courses were completed by IT Accurate.
              </li>
              <li>
                The SAP S/4HANA certification offered by IT Accurate will equip
                you with valuable skills, enhancing your competitiveness in the
                job market.
              </li>
              <li>
                IT Accurate provides comprehensive guidance for your SAP S/4HANA
                global certification, ensuring a 100% passing guarantee in
                examinations such as SAP Certification, SAP S/4HANA Platform
                Development Certification, and various other global exams.
              </li>
            </ul>
          </div>
          <div className="certification-image">
            <img src={certificateImg} alt="DevOps Certification" />
          </div>
        </div>
      </section>

      <Roadmap heading="SAP modules" roadmapData={modulesData} />

      {/* devops project Section
            <div className="slider-container">
                <h2 className="slider-heading">Devops Project</h2>
                <RegularSlider sliderSettings={sliderSettings} images={imageList} />
            </div> */}
      <CourseOpportunities pageName="SAP_ABAP" />
      <Faq Faqs={faqs} />

      {/* <div className="slider-container">
                <h2 className="slider-heading">SAP ABAP Training courses review</h2>
                <RegularSlider sliderSettings={sliderSettings} images={reviewList} />
            </div> */}

      <MarqueeLogos />
    </div>
  );
};

export default SAPABAP;
